import {CustomFormField, IQuickObject, SurveyObject, SurveyObjectQuestion} from "../../../types/interfaces";
import React from "react";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import {Box, Button, IconButton, InputAdornment, Modal, Tab, Tabs, Typography} from "@mui/material";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import {EditObject} from "../../../Shared/Components/EditObject";
import {generateCode} from "../SurveyEditorView";
import {AuthContext} from "../../../Library/AuthContext";
import {BankDataController_QuickObject} from "../../Bank/BankDataController_QuickObject";
import {DataGrid, GridCellParams, GridRowParams, GridValidRowModel} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";


function ObjectListAddView({ onClose, onAddNewObject }: { onClose: () => void, onAddNewObject: (obj: SurveyObject) => boolean }) {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickObject(userToken).getAll();
            setQuickObjectsList(all);
        }
        loadData().then();
    }, [userToken]);



    // const [quickObject, setList] = React.useState<ISurveyAnswer[]>([]);
    const [quickObjectsList, setQuickObjectsList] = React.useState<IQuickObject[]>([]);


    const columns = [
        { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'content.Location', headerName: 'Location', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
            let quickObject: IQuickObject = params.row as IQuickObject;
            return quickObject.content.location;
        } },
        { field: 'content.questions.length', headerName: 'Question Count', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
            let quickObject: IQuickObject = params.row as IQuickObject;
            return quickObject.content.questions.length;
        } },
        {
            field: 'debug',
            headerName: '',
            sortable: false,
            width: 500,
            renderCell: (params: GridCellParams) => (
                <pre>
                    {JSON.stringify(params.row)}
                </pre>
            )
        },
        {
            field: 'add_btn',
            headerName: '',
            sortable: false,
            width: 75,
            renderCell: (params: GridCellParams) => {
                return (<IconButton color={"warning"} onClick={() => {
                    let quickObject: IQuickObject = {...params.row as IQuickObject};
                    quickObject.content.key = generateCode();
                    onAddNewObject(quickObject.content);
                    onClose();
                }}>
                    <FontAwesomeIcon icon={faPlus} />
                </IconButton>)
            }
        }
    ]

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            let rowData = row as IQuickObject & { _id: string };
            return (<>
                <div style={{padding: '1rem', height: '100%'}}>
                    <DataGrid
                        style={{height: '100%'}}
                        columns={[
                            { field: 'key', headerName: 'Key', width: 150, editable: false },
                            { field: 'questionText', headerName: 'Question Text', width: 400, editable: false },
                            { field: 'type.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                                    let question = params.row as SurveyObjectQuestion;
                                    return question.type.type;
                                }},
                        ]}
                        rows={rowData.content.questions || []}
                        getRowId={(row) => row.key}
                    />
                </div>
            </>);
        }, []
    );


    return (


        <DataGridPro
            // make sure to set height to 100% so that the grid fills the whole container
            style={{ height: 'calc( 100% - 5rem )' }}
            rows={quickObjectsList}
            columns={columns}
            getRowId={(row: GridValidRowModel) => row._id}
            pageSizeOptions={[200]}
            disableRowSelectionOnClick
            getDetailPanelHeight={(row) => {
                return 275;
            }}
            getDetailPanelContent={ getDetailPanelContent }

        />
    );
}

function NewQuestionForm({ onClose, onAddNewObject }: { onClose: () => void, onAddNewObject: (obj: SurveyObject) => boolean }) {
    const [item, setItem] = React.useState<any>({
        key: generateCode(),
    });

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "location",
                    label: "Object Name",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "key",
                    label: "Key",
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="generate new code"
                                onClick={() => {
                                    // randomize the code.
                                    setItem({...item, key: generateCode()});
                                }}
                                edge="end"
                            >
                                <ShuffleIcon />
                            </IconButton>
                        </InputAdornment>
                },
                {
                    kind: CustomFormFieldTypes.TOGGLE,
                    size: CustomFormFieldSizes.FULL,
                    key: "isConditional",
                    label: "Is Conditional",
                }
            ];

            return fields;
        },
        [item]
    );
    
    return (<>
        <EditObject
            item={item}
            setItem={setItem}
            form={fields}
            columns={12}
        />
        <br />

        <Button type={"submit"} variant={"contained"} size={"large"} disabled={ item.key === undefined || item.key === "" || item.location === undefined || item.location === "" } fullWidth={true} onClick={() => {

            let objectToCreate: SurveyObject = {
                key: (item.key as string),
                location: (item.location as string),
                isConditional: (item.isConditional as boolean),
                questions: []
            }

            if (onAddNewObject(objectToCreate)) {
                setItem({
                    key: generateCode(),
                });
                onClose();
            }
            else {
                alert("Invalid Object");
            }
        }}>CREATE</Button>

        <br />
        <br />

        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
            setItem({
                location: "",
                key: generateCode(),
            })
        }}>Clear</Button>
        {/*disabled={betaCode === undefined || (betaCode.code === "") }*/}
    </>);
};




export const AddNewObject = ({ open, onClose, onAddNewObject } : { open: boolean, onClose: () => void, onAddNewObject: (obj: SurveyObject) => boolean }) => {

    const [tab, setTab] = React.useState(0);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabContent() {
        switch (tab) {
            case 0:
                return <NewQuestionForm onClose={onClose} onAddNewObject={onAddNewObject} />;
            case 1:
                return <ObjectListAddView onClose={onClose} onAddNewObject={onAddNewObject} />;
            default:
                return <div>Unknown Tab</div>;
        }
    }

    return (<>
        <Modal open={open} onClose={() => {
            onClose();
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Create New Object
                </Typography>


                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={(e, nv) => {
                        setTab(nv);
                    }} aria-label="basic tabs example">
                        <Tab label="New" {...a11yProps(0)}  />
                        <Tab label="Bank" {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <br />

                <TabContent />



            </Box>
        </Modal>
    </>);
};
