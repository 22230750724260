// AuthContext.tsx
import React, { createContext } from 'react';
import {IUser} from "../types/interfaces";

// Create the authentication context
export interface AuthContextType {
    // isLoggedIn: boolean;
    // login: () => void;
    // logout: () => void;
    userToken: string | null | undefined;
    setUserToken: React.Dispatch<React.SetStateAction<string | null>>;
    user: IUser | null | undefined;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
    adminUserToken : string | null | undefined;
    setAdminUserToken: React.Dispatch<React.SetStateAction<string | null>>;

    tokenExpired: boolean;
    setTokenExpired: React.Dispatch<React.SetStateAction<boolean>>;
    // onTokenExpired: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);


