// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {DataGrid, GridCellParams, GridColDef, GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {CustomFormField, ISurvey, IUser, SurveyObjectQuestion} from "../../types/interfaces";
import {SurveyDataController} from "./SurveyDataController";
import {AuthContext} from "../../Library/AuthContext";
import {Box, Button, Modal, Typography} from "@mui/material";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {UserDataController} from "../Users/UserDataController";
import {EditObject} from "../../Shared/Components/EditObject";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";


function CloneArchivedSurveyModal(props: { usersList: IUser[], onSave: ((survey: ISurvey) => void), onCancel: (() => void), survey: ISurvey })  {

    // const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        assigned_user_id: null,
        content: props.survey.content,
        locations: props.survey.locations,
        // selected technician
    });

    React.useEffect(() => {
        setSurvey({
            caseNumber: survey.caseNumber,
            assigned_user_id: survey.assigned_user_id,
            content: props.survey.content,
            locations: props.survey.locations,
            // selected technician
        });
    }, [props.usersList, props.survey, survey.assigned_user_id, survey.caseNumber]);



    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Clone Survey: {props.survey.caseNumber}
            </Typography>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            {/*<p>Content: {JSON.stringify(survey)}</p>*/}

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                props.onSave(survey);
            }} disabled={ survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );
}
export function CloneArchivedSurveyButton(props: {
    userToken: string | null | undefined,
    setList: ((list: ISurvey[]) => void),
    updateList: (() => void),
    // usersList: IUser[],
    survey: ISurvey
})
{

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [show, setShow] = React.useState<boolean>(false);

    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    // const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])

    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT));
                // setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken]);

    return (<>
        <Modal
            open={show}
            onClose={() => { setShow(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {<>
                <CloneArchivedSurveyModal
                    usersList={usersList}
                    survey={props.survey}
                    onSave={async (survey: ISurvey) => {
                        if (props.userToken === null || props.userToken === undefined) {
                            return;
                        }
                        let surveyToClone = survey;
                        surveyToClone.isArchived = false;
                        await new SurveyDataController(props.userToken).create(surveyToClone);

                        // let all = await new SurveyDataController(props.userToken).getAll();
                        // setList(all);
                        props.updateList();
                        setShow(false);
                    }}
                    onCancel={() => { setShow(false) }}
                ></CloneArchivedSurveyModal>
            </>}
        </Modal>

        <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
            setShow(true);
        }}>
            Clone
            {/*<FontAwesomeIcon icon={faCopy} />*/}
        </Button>
    </>);
}


function ArchivedSurveysListView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;


    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };

    const [deleteActive, setDeleteActive] = React.useState<boolean>(false);

    const customButtonsMemo = React.useMemo(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [];

        // Add button

        buttons.push({
            icon: <FontAwesomeIcon icon={faTrash} />,
            label: "Delete Selected",
            action: () => {

                async  function deleteSurveys() {
                    if (userToken === null || userToken === undefined) {
                        return;
                    }
                    for (let i = 0; i < selectionModel.length; i++) {
                        let id = selectionModel[i];
                        await new SurveyDataController(userToken).delete(id);
                    }
                    let all = await new SurveyDataController(userToken).getAllArchived();
                    setList(all);
                }

                setDeleteActive(true);
                if (window.confirm(`Are you sure you want to delete ${selectionModel.length} surveys?`)) {
                    deleteSurveys().then(() => {
                        setDeleteActive(false);
                    });
                }
                // setAddNewObjectModalShown(true)
            },
            disabled: selectionModel.length === 0 || deleteActive,
        });

        return buttons;
    }, [selectionModel.length]);


    const columns = React.useMemo(() => {
        let columns: GridColDef[] = [
            { field: 'caseNumber', headerName: 'Case Number', width: 300, editable: false },
            { field: 'status', headerName: 'Status', width: 300, editable: false },
            { field: 'surveyDate', headerName: 'Survey Date', width: 300, editable: false,
                valueGetter: params => {
                    try {
                        let survey = params.row as ISurvey;
                        if (survey.surveyDate !== undefined && survey.surveyDate !== null){
                            return new Date(survey.surveyDate).toLocaleDateString();
                        }
                        else {
                            return ""
                        }
                    }
                    catch (e: any) {
                        // alert(e.message)
                        return ""
                    }
                }
            },
            { field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false, valueGetter: params => {
                    try {
                        let survey = params.row as ISurvey;
                        if (survey.assigned_user_id !== undefined){
                            let user = survey.assigned_user_id as IUser;
                            return user.name;
                        }
                        else {
                            return "Unassigned"
                        }
                    }
                    catch (e) {
                        return "Unassigned"
                    }

                } },
            { field: 'surveyOwner', headerName: 'Survey Owner', width: 300, editable: false },


            {
                field: 'restore_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (
                    <Button variant="outlined" color="primary" fullWidth={true} onClick={() => {
                        async function restoreRow() {
                            let row = params.row as ISurvey & { _id: string };
                            row.isArchived = false;

                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            await new SurveyDataController(userToken).update(row._id, row);
                            let all = await new SurveyDataController(userToken).getAllArchived();
                            setList(all);
                        }

                        if (window.confirm("Are you sure you want to restore this survey?")){
                            restoreRow().then();
                        }

                    }}>
                        Restore
                        {/*<FontAwesomeIcon icon={faTrash} />*/}
                    </Button>
                ),
            },


            {
                field: 'clone_btn',
                headerName: '',
                sortable: false,
                width: 100,
                renderCell: (params: GridCellParams) => (<>

                        <CloneArchivedSurveyButton
                            userToken={userToken}
                            setList={setList}
                            survey={params.row as ISurvey}
                            updateList={() => {
                                async function loadData() {
                                    if (userToken === null || userToken === undefined) {
                                        return;
                                    }
                                    let all = await new SurveyDataController(userToken).getAll();
                                    setList(all);
                                }

                                loadData().then();
                            }}
                        />
                    </>
                ),
            }

        ]


        if (user?.role === UserRole.ADMIN) {
            columns.push({
                field: 'delete_btn',
                headerName: '',
                sortable: false,
                width: 180,
                renderCell: (params: GridCellParams) => (
                    <Button variant="outlined" color="primary" fullWidth={true} onClick={() => {
                        async function removeRow() {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            await new SurveyDataController(userToken).delete(params.row._id);
                            let all = await new SurveyDataController(userToken).getAllArchived();
                            setList(all);
                        }

                        if (window.confirm("Are you sure you want to archive this survey?")){
                            removeRow().then();
                        }
                    }}>
                        Fully Delete
                    </Button>
                ),
            })
        }

        return columns;

    }, [user, userToken]);

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new SurveyDataController(userToken).getAllArchived();
            setList(all);
        }
        loadData().then();
    }, [userToken]);

    const [list, setList] = React.useState<ISurvey[]>([]);



    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Archived Surveys"} customButtons={customButtonsMemo}>
                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[200]}
                    disableRowSelectionOnClick
                    checkboxSelection={user?.role === UserRole.ADMIN}
                    onRowSelectionModelChange={handleSelection}
                    rowSelectionModel={selectionModel}
                />
            </AuthenticatedLayout>
        </div>
    );
}

export default ArchivedSurveysListView;