import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {BankDataController_QuickQuestion} from "./BankDataController_QuickQuestion";
import {CustomFormField, IQuickQuestion} from "../../types/interfaces";
import {GridCellParams, GridValidRowModel} from "@mui/x-data-grid";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGridPro} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import {EditObject} from "../../Shared/Components/EditObject";
import {QuickSelectOptions} from "../Surveys/SurveyEditor/QuestionView";

export function QuestionsListView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickQuestion(userToken).getAll();
            setQuickQuestionsList(all);
        }
        loadData().then();
    }, [userToken]);

    const [quickQuestionsList, setQuickQuestionsList] = React.useState<IQuickQuestion[]>([]);


    const columns = [
        // { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'content.key', headerName: 'Key', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.key;
            }},
        { field: 'content.questionText', headerName: 'Question Text', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.questionText;
            }},
        { field: 'content.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestion = params.row as IQuickQuestion;
                return quickQuestion.content.type.type;
            }},

        { field: 'edit_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<EditBankQuestion question={params.row as IQuickQuestion} saveQuestion={(question) => {
                    // TODO: Implement
                    return false;

                }} />)
            }},

        { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
            return (<IconButton color={"error"} onClick={() => {
                if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                    let item = params.row as IQuickQuestion & { _id: string };
                    if (userToken === null || userToken === undefined) {
                        alert("You must be logged in to export.");
                        return;
                    }
                    new BankDataController_QuickQuestion(userToken).delete(item._id).then(async (response) => {

                        let all = await new BankDataController_QuickQuestion(userToken).getAll();
                        setQuickQuestionsList(all);
                        // alert("Deleted successfully.");
                        // setQuickObjectsList(quickObjectsList.filter((item: IQuickObject) => item._id !== params.row._id));
                    });
                }
            }}><DeleteIcon /></IconButton>)
        }},
    ]


    return (
<>
        <DataGridPro
            // make sure to set height to 100% so that the grid fills the whole container
            style={{ height: 'calc( 100% - 5rem )' }}
            // rows={userDataController.data}
            rows={quickQuestionsList}
            columns={columns}
            getRowId={(row: GridValidRowModel) => row._id}
            pageSizeOptions={[200]}
            disableRowSelectionOnClick
            // processRowUpdate={processRowUpdate}
            // onProcessRowUpdateError={handleProcessRowUpdateError}
            // getRowId={(row) => row.key || row.location}

        />


</>
    );
}

export const EditBankQuestion = ({ question, saveQuestion } : { question: IQuickQuestion, saveQuestion: (question: IQuickQuestion) => boolean }) => {

    const [open, setOpen] = React.useState(false);

    // TODO: convert the type to something usable?


    const [item, setItem] = React.useState<any>({...question.content, ...question.content.type});

    // const [customFormFields, setCustomFormFields] = React.useState<CustomFormField[]>([]);
    const formFieldsMemo = React.useMemo(() => {
        let customFormFields: CustomFormField[] = [];

        customFormFields.push({
            key: "key",
            kind: CustomFormFieldTypes.TEXT,
            label: "Key",
            size: CustomFormFieldSizes.FULL,
            readonly: true
        })

        customFormFields.push({
            key: "questionText",
            kind: CustomFormFieldTypes.TEXT,
            label: "Question Text",
            size: CustomFormFieldSizes.FULL,
            readonly: true
        })

        customFormFields.push({
            kind: CustomFormFieldTypes.TOGGLE,
            size: CustomFormFieldSizes.FULL,
            key: "required",
            label: "Required",
            readonly: true
        })

        customFormFields.push({
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "type",
            label: "Type",
            options: [
                {label: "Text", value: "text"},
                {label: "Toggle", value: "toggle"},
                {label: "Select", value: "select"},
                {label: "Photo", value: "photo"},
                {label: "Geo Coordinate", value: "geoCoordinate"},
                {label: 'Document', value: 'document'},
                // {label: 'Signature', value: 'signature'},
                {label: 'Multi Select', value: 'multiSelect'},
                {label: 'Instruction', value: 'instruction'},
            ],
            readonly: true
        })

        if (item.type === 'select' || item.type === 'multiSelect') {
            customFormFields.push({
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "options",
                    label: `Options (comma separated)`,
                    readonly: true
                }
            );

            customFormFields.push({
                kind: CustomFormFieldTypes.PASSTHRU,
                size: CustomFormFieldSizes.FULL,
                key: "quickOptions",
                label: "Quick Options",
                passthru: QuickSelectOptions,
                passthruProps: { item: item, setItem: setItem, surveyObject: question }
            })
        }


        // if (item.type === 'select' && item.options !== undefined) {
        //     customFormFields.push({
        //             kind: CustomFormFieldTypes.SELECT,
        //             size: CustomFormFieldSizes.FULL,
        //             key: "defaultValue",
        //             label: `Default Value`,
        //             options: item.options.split(",").map((opt: string) => { return { label: opt.trim(), value: opt.trim() }; })
        //         }
        //     );
        // }

        return customFormFields;
    }, [ item, question ])


    return (<>
        <IconButton color={"secondary"} onClick={() => {
            setOpen(true);
        }}><EditIcon /></IconButton>
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            {open ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Edit Question From Bank
                    </Typography>

                    <br />

                    <EditObject
                        item={item}
                        setItem={setItem}
                        form={formFieldsMemo}
                        columns={12}
                    />

                    <br />

                    <pre>{JSON.stringify(question, undefined, 4)}</pre>

                    <Button disabled={true} type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                        alert("Not implemented yet.");
                    }}>SAVE</Button>
                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};



// export const EditBankQuestion = ({ question, saveQuestion } : { question: IQuickQuestion, saveQuestion: (question: IQuickQuestion) => boolean }) => {
//
//     const [open, setOpen] = React.useState(false);
//
//     return (<>
//         <Modal open={open} onClose={() => {
//             setOpen(false);
//         }}>
//             {open ? (<>
//                 <Box
//                     sx={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         width: '60%',
//                         bgcolor: 'background.paper',
//                         boxShadow: 24,
//                         p: 4,
//                         overflowY: 'auto',
//                         maxHeight: '90vh',
//                     }}
//                 >
//                     <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
//                         Edit Question From Bank
//                     </Typography>
//
//                     <br />
//
//                     <pre>{JSON.stringify(question, undefined, 4)}</pre>
//
//                     <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
//                         alert("Not implemented yet.");
//                     }}>SAVE</Button>
//                 </Box>
//             </>) : (<></>)}
//         </Modal>
//     </>);
// };