import {ToggleAnswer} from "../../../../types/interfaces";
import {Switch} from "@mui/material";
import React from "react";
import {AnswerViewProps} from "../SurveyAnswerEdit";

export function ToggleAnswerView(props: AnswerViewProps) {
    const { answer } = props;

    let toggleAnswer: ToggleAnswer = answer as ToggleAnswer;
    if (toggleAnswer === undefined) {
        toggleAnswer = { toggle: { data: false } } as ToggleAnswer;
    }

    return (<>

        <div style={{ padding: '0.5rem' }}>
            <Switch
                checked={toggleAnswer.toggle.data ?? false}
                onChange={(e) => {
                    let newToggleAnswer = { toggle: { data: e.target.checked as unknown as boolean } } as ToggleAnswer;
                    let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                    if (surveyAnswerObject === undefined) {
                        surveyAnswerObject = { values: {} };
                    }
                    let newValues = surveyAnswerObject.values;
                    newValues[props.question.key] = newToggleAnswer;
                    let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                    props.setSurveyAnswer(newSurveyAnswer);
                }}
                disabled={props.surveyAnswer.isCompleted}
            />
            <label>{props.question.questionText}</label>
        </div>

    </>);
}