import {SelectAnswer} from "../../../../types/interfaces";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import {AnswerViewProps} from "../SurveyAnswerEdit";

export function SelectAnswerView(props: AnswerViewProps) {
    const { answer } = props;

    let selectAnswer: SelectAnswer = answer as SelectAnswer;
    if (selectAnswer === undefined) {
        selectAnswer = { select: { data: "" } } as SelectAnswer;
    }

    return (<>
        <FormControl fullWidth>
            <InputLabel id={"_label_" + props.question.key}>{props.question.questionText}</InputLabel>
            <Select
                labelId={"_label_" + props.question.key}
                id={"_input_" + props.question.key}
                value={ selectAnswer.select.data }
                label={props.question.questionText}
                onChange={(e)=>{
                    let newSelectAnswer = { select: { data: e.target.value as unknown as string } } as SelectAnswer;
                    let surveyAnswerObject = props.surveyAnswer.answers[props.surveyObject.key];
                    if (surveyAnswerObject === undefined) {
                        surveyAnswerObject = { values: {} };
                    }
                    let newValues = surveyAnswerObject.values;
                    newValues[props.question.key] = newSelectAnswer;
                    let newSurveyAnswer = {...props.surveyAnswer, answers: {...props.surveyAnswer.answers, [props.surveyObject.key]: {...surveyAnswerObject, values: newValues}}};
                    props.setSurveyAnswer(newSurveyAnswer);
                    console.log("newValues", newValues);
                }}
                disabled={props.surveyAnswer.isCompleted}
            >
                {(props.question.type.options ?? []).map((option, index) => {
                    return <MenuItem key={option} value={option}>{option}</MenuItem>
                })}
            </Select>
        </FormControl>
    </>);

}