
export enum UserRole {
    ADMIN = 'Admin',
    CLIENT = 'Client',
    TECHNICIAN = 'Technician',
    MANAGER = 'Manager',
}

export enum CustomFormFieldTypes {
    TEXT = "text",
    PHONE_NUMBER = "phone_number",
    EMAIL = "email",
    SELECT = "select",
    TOGGLE = "toggle",
    DATE = "date",
    PASSWORD = "password",
    TEXTAREA = "textarea",
    // GRID = "GRID",
    PASSTHRU = "PASSTHRU",
    DATETIME = "datetime",
}

export enum CustomFormFieldSizes {
    SMALL = "small",
    MEDIUM = "medium",
    HALF = "half",
    LARGE = "large",
    FULL = "full",
    ONE = "one",
    TWO = "two",
    THREE = "three",
}

export enum ReportType {
    Standard = "Standard",
    // Simplified = "Simplified",
    FullResolutionImages = "FullResolutionImages",
    Exception = "Exception",
    SpreadSheet = "SpreadSheet"
}