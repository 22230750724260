import {CustomFormField} from "../../types/interfaces";
import DebugView from "../../Library/DebugView";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import {Grid} from "@mui/material";
import React from "react";
import {EditText} from "./EditText";
import {EditSelect} from "./EditSelect";
import {EditToggle} from "./EditToggle";
import {EditTextArea} from "./EditTextArea";
import {EditDate} from "./EditDate";
import {EditDateTime} from "./EditDateTime";

export function EditObject<T>(props: { item: T, setItem: any, form: CustomFormField[], columns: number }) {

    if (props.item === null || props.item === undefined) {

        return (<>
            Loading...
            {/*<DebugView value={props.form} />*/}
        </>);

    }

    function GetSizeAttributes(size: CustomFormFieldSizes) {
        switch (size) {
            case CustomFormFieldSizes.ONE:
                return {xs: 12, sm: 1, md: 1, lg: 1};
            case CustomFormFieldSizes.TWO:
                return {xs: 12, sm: 2, md: 2, lg: 2};
            case CustomFormFieldSizes.THREE:
                return {xs: 12, sm: 3, md: 3, lg: 3};
            case CustomFormFieldSizes.SMALL:
                return {xs: 12, sm: 6, md: 4, lg: 3};
            case CustomFormFieldSizes.MEDIUM:
                return {xs: 12, sm: 6, md: 6, lg: 4};
            case CustomFormFieldSizes.HALF:
                return {xs: 12, sm: 6, md: 6, lg: 6};
            case CustomFormFieldSizes.LARGE:
                return {xs: 12, sm: 8, md: 8, lg: 8};
            case CustomFormFieldSizes.FULL:
                return {xs: 12, sm: 12, md: 12, lg: 12};
            default:
                return {xs: 12, sm: 12, md: 12, lg: 12};
        }
    }


    return (<>
        {/*<h1>Edit Object</h1>*/}
        {/*<DebugView value={props.item} />*/}
        {/*<DebugView value={props.form} />*/}

        <Grid container spacing={2} sx={{p : 1}} columns={props.columns}>
            {props.form.map((field: CustomFormField) => {
                switch (field.kind) {
                    case CustomFormFieldTypes.TEXT:
                    case CustomFormFieldTypes.EMAIL:
                    case CustomFormFieldTypes.PHONE_NUMBER:
                    case CustomFormFieldTypes.PASSWORD:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <EditText item={props.item} setItem={props.setItem} field={field}/>
                            </Grid>
                        );
                    case CustomFormFieldTypes.TEXTAREA:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <EditTextArea item={props.item} setItem={props.setItem} field={field}/>
                            </Grid>
                        );
                    case CustomFormFieldTypes.SELECT:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <EditSelect item={props.item} setItem={props.setItem} field={field}/>
                            </Grid>
                        );
                    case CustomFormFieldTypes.TOGGLE:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <EditToggle item={props.item} setItem={props.setItem} field={field}/>
                            </Grid>
                        );

                    case CustomFormFieldTypes.DATE:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <EditDate item={props.item} setItem={props.setItem} field={field}/>
                            </Grid>
                        );

                    case CustomFormFieldTypes.DATETIME:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <EditDateTime item={props.item} setItem={props.setItem} field={field}/>
                            </Grid>
                        );
                    // case CustomFormFieldTypes.GRID:
                    //     return (
                    //         <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                    //             <EditGrid item={props.item} setItem={props.setItem} field={field}/>
                    //         </Grid>
                    //     );
                    case CustomFormFieldTypes.PASSTHRU:
                        return (
                            <Grid key={field.key} item {...GetSizeAttributes(field.size)}>
                                <>{field.passthru && <field.passthru item={props.item} setItem={props.setItem} {...field.passthruProps} field={field} />}</>
                                {/*<field.passthru {...props} />*/}
                                {/*field.passthru(props)*/}
                                {/*{field.passthru(props)}*/}
                                {/*field.passthru(props)*/}
                            </Grid>
                        );

                    default:
                        return (
                            <Grid key={field.key} item xs={12}>
                                <DebugView value={`Field Type for field '${field.key}' (${field.kind}) isn't a valid field type.`} />
                                {/*<DebugView value={field} />*/}
                            </Grid>
                        );
                }
            })}
        </Grid>



    </>)
}
