import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {BankDataController_QuickObject} from "./BankDataController_QuickObject";
import {IQuickObject, SurveyObjectQuestion} from "../../types/interfaces";
import {DataGrid, GridCellParams, GridRowParams, GridValidRowModel} from "@mui/x-data-grid";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGridPro} from "@mui/x-data-grid-pro";

export function ObjectListView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickObject(userToken).getAll();
            setQuickObjectsList(all);
        }
        loadData().then();
    }, [userToken]);



    // const [quickObject, setList] = React.useState<ISurveyAnswer[]>([]);
    const [quickObjectsList, setQuickObjectsList] = React.useState<IQuickObject[]>([]);

    const columns = [
        // { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'content.Location', headerName: 'Location', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickObject: IQuickObject = params.row as IQuickObject;
                return quickObject.content.location;
            } },
        { field: 'content.questions.length', headerName: 'Question Count', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickObject: IQuickObject = params.row as IQuickObject;
                return quickObject.content.questions.length;
            } },
        { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"error"} onClick={() => {
                    if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                        let item = params.row as IQuickObject & { _id: string };
                        if (userToken === null || userToken === undefined) {
                            alert("You must be logged in to export.");
                            return;
                        }
                        new BankDataController_QuickObject(userToken).delete(item._id).then(async (response) => {
                            let all = await new BankDataController_QuickObject(userToken).getAll();
                            setQuickObjectsList(all);
                        });
                    }
                }}><DeleteIcon /></IconButton>)
            }},
    ]

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            let rowData = row as IQuickObject & { _id: string };
            return (<>
                <div style={{padding: '1rem', height: '100%'}}>
                    <DataGrid
                        style={{height: '100%'}}
                        columns={[
                            { field: 'key', headerName: 'Key', width: 150, editable: false },
                            { field: 'questionText', headerName: 'Question Text', width: 400, editable: false },
                            { field: 'type.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                                    let question = params.row as SurveyObjectQuestion;
                                    return question.type.type;
                                }},
                        ]}
                        rows={rowData.content.questions || []}
                        getRowId={(row) => row.key}
                    />
                </div>
            </>);
        }, []
    );

    return (


        <DataGridPro
            // make sure to set height to 100% so that the grid fills the whole container
            style={{ height: 'calc( 100% - 5rem )' }}
            // rows={userDataController.data}
            rows={quickObjectsList}
            columns={columns}
            getRowId={(row: GridValidRowModel) => row._id}
            pageSizeOptions={[200]}
            disableRowSelectionOnClick
            // processRowUpdate={processRowUpdate}
            // onProcessRowUpdateError={handleProcessRowUpdateError}
            // getRowId={(row) => row.key || row.location}

            getDetailPanelHeight={(row) => {
                return 275;
            }}
            getDetailPanelContent={ getDetailPanelContent }

        />
    );
}

