// https://saiankit.medium.com/setting-up-mongodb-on-macos-catalina-common-errors-and-their-solutions-72fd66fb8a86
import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Modal,
    Grid,
    Paper,
    TextField,
    Toolbar,
    Typography,
    Alert,
    Stack, InputAdornment, IconButton
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {AuthContext} from "../Library/AuthContext";
// import AccountCreationModal from "./AccountCreationModal";
import {CustomFormField} from "../types/interfaces";
import {EditObject} from "../Shared/Components/EditObject";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../types/enums";
import {Send as SendIcon, NextPlan as NextPlanIcon, VisibilityOff, Visibility} from "@mui/icons-material";


// function ScrollPane(props: any) {
//     return (
//         <div className="scroll-pane">
//             {props.children}
//         </div>
//     )
// }



function AccountRecoveryModal({ closeAccountRecovery } : { closeAccountRecovery: () => void }) {
    enum CodeSendingStatus {
        NotSent,
        Sending,
        Sent
    }

    const [codeSendingStatus, setCodeSendingStatus] = React.useState<CodeSendingStatus>(CodeSendingStatus.NotSent);
    const [item, setItem] = React.useState<any>({});
    const form = React.useMemo(() => {
        let fields: CustomFormField[] = [];

        fields.push({
            kind: CustomFormFieldTypes.EMAIL,
            size: CustomFormFieldSizes.FULL,
            key: "email_address",
            label: `Email Address`,
            endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        disabled={codeSendingStatus === CodeSendingStatus.Sending || codeSendingStatus === CodeSendingStatus.Sent}
                        aria-label="generate new code"
                        onClick={() => {
                            setCodeSendingStatus(CodeSendingStatus.Sending);
                            RequestCode(item.email_address).then(r => {
                                setCodeSendingStatus(CodeSendingStatus.Sent);
                            });
                            // setEmailVerificationSent(VerificationSendingStatus.Sending);
                            // requestVerificationCode().then(r => {});
                        }}
                        edge="end"
                    >
                        <SendIcon />
                    </IconButton>
                </InputAdornment>
        });

        if(item.email_address === null || item.email_address === undefined) {
            return fields;
        }

        if(codeSendingStatus === CodeSendingStatus.NotSent) {
            return fields;
        }

        fields.push({
            kind: CustomFormFieldTypes.EMAIL,
            size: CustomFormFieldSizes.FULL,
            key: "code",
            label: `Code (sent to ${item.email_address})`
        });

        if(item.code === null || item.code === undefined) {
            return fields;
        }

        fields.push({
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "password",
            label: `Password`
        });

        fields.push({
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "confirmPassword",
            label: `Confirm Password`,
            endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        disabled={
                            item.password !== item.confirmPassword ||
                            item.password === undefined ||
                            item.password === null ||
                            item.password === "" ||
                            item.confirmPassword === undefined ||
                            item.confirmPassword === null ||
                            item.confirmPassword === ""
                        }
                        aria-label="Submit"
                        onClick={() => {
                            VerifyCode(item.email_address, item.code, item.password).then(r => {
                                closeAccountRecovery();
                            });
                        }}
                        edge="end"
                    >
                        <NextPlanIcon />
                    </IconButton>
                </InputAdornment>
        });

        return fields;
    }, [codeSendingStatus, item, closeAccountRecovery, CodeSendingStatus.NotSent, CodeSendingStatus.Sending, CodeSendingStatus.Sent]);

    // const {
    //     // userToken,
    //     setUserToken
    // } = React.useContext(AuthContext)!;
    // const navigate = useNavigate();

    // const [emailAddress, setEmailAddress] = React.useState<string>("");
    // const [password, setPassword] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    // function onEnterKeyUpLogin(event: any) {
    //     if (event.key === 'Enter'){
    //         if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
    //         if (password === undefined || password === "") return setErrorMessage("Invalid Password");
    //         Login(emailAddress, password).then(() => {});
    //     }
    // }

    async function RequestCode(email_address: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/recovery/requestCode`, {
                method: "POST",
                headers: {
                    // "authorization": `Bearer ${this.userToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                })
            });
            console.log(results)
            // let data = await results.json();
            setErrorMessage(undefined);
            // setUserToken(token);
            // navigate('/dashboard'); // TODO: figure out how to make this work with the useCallback
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Error Occurred");
        }
    }

    async function VerifyCode(email_address: string, code: string, password: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/recovery/verifyCode`, {
                method: "POST",
                headers: {
                    // "authorization": `Bearer ${this.userToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                    code,
                    password
                })
            });
            console.log(results)
            // let data = await results.json();
            setErrorMessage(undefined);
            // setUserToken(token);
            // navigate('/dashboard'); // TODO: figure out how to make this work with the useCallback
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Error Occurred");
        }
    }

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Password Recovery
            </Typography>

            <EditObject item={item} setItem={setItem} form={form} columns={2}></EditObject>

                {errorMessage !== undefined && (
                    // <Grid item xs={12}>
                        <Alert severity="error">{errorMessage}</Alert>
                    // </Grid>
                )}
            {/*</Grid>*/}
        </Box>
    );
}

function LoginModal({ openAccountRecovery } : { openAccountRecovery: () => void }) {

    const {
        // userToken,
        setUserToken,
        setTokenExpired
    } = React.useContext(AuthContext)!;
    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    function onEnterKeyUpLogin(event: any) {
        if (event.key === 'Enter'){
            if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
            if (password === undefined || password === "") return setErrorMessage("Invalid Password");
            Login(emailAddress, password).then(() => {});
        }
    }
    
    async function Login(email_address: string, password: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/auth/login`, {
                method: "POST",
                headers: {
                    // "authorization": `Bearer ${this.userToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                    password
                })
            });
            let data = await results.json();
            console.log(data);
            let { token } = data;
            if (token === undefined) {
                return setErrorMessage("Invalid Credentials")
            }
            setErrorMessage(undefined);
            setTokenExpired(false);
            setUserToken(token);
            navigate('/'); // TODO: figure out how to make this work with the useCallback
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Invalid Credentials");
        }
    }


    // babel-preset-react-app is part of the create-react-app project, which
    // remote: #8 41.58 is not maintianed anymore. It is thus unlikely that this bug will
    // remote: #8 41.58 ever be fixed. Add "@babel/plugin-proposal-private-property-in-object" to
    // remote: #8 41.58 your devDependencies to work around this error. This will make this message

    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                LOGIN
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField fullWidth={true} label="Email Address" variant="outlined" type="email" value={emailAddress} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmailAddress(event.target.value);
                    }}  />
                </Grid>
                <Grid item xs={12}>
                    {/*<TextField fullWidth={true} label="Password" variant="outlined" type="password" value={password} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
                    {/*    setPassword(event.target.value);*/}
                    {/*}} onKeyUp={onEnterKeyUpLogin} />*/}

                    <TextField
                        fullWidth={true}
                        label="Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value);
                        }}
                        onKeyUp={onEnterKeyUpLogin}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />


                </Grid>
                <Grid item xs={12}>
                    <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                        if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
                        if (password === undefined || password === "") return setErrorMessage("Invalid Password");
                        setErrorMessage(undefined);
                        Login(emailAddress, password).then(() => {});
                    }}>Login</Button>
                </Grid>

                <Grid item xs={12}>
                    <Button type={"submit"} size={"large"} fullWidth={true} onClick={() => {
                        openAccountRecovery();
                    }}>Reset Password</Button>
                </Grid>
                {errorMessage !== undefined && (
                    <Grid item xs={12}>
                        <Alert severity="error">Invalid Login</Alert>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}


// const baseURL = "websocketexample.justins.tech";
// const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
// const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
// const WS_URL = 'ws://127.0.0.1:3000';


enum UnauthenticatedViewModalMode {
    Hidden,
    Login,
    Register,
    AccountRecovery
}

function UnauthenticatedMainView() {
    // const {setUserToken} = props;



    const navigate = useNavigate();

    const {
        userToken,
        // setUserToken
    } = React.useContext(AuthContext)!;


    const [mode, setMode] = React.useState<UnauthenticatedViewModalMode>(UnauthenticatedViewModalMode.Hidden);

    // const [showLogin, setShowLogin] = React.useState(false);
    // const [showRegister, setShowRegister] = React.useState(false);

    const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
    let titleText = "POST"
    switch (baseURL) {
        case "127.0.0.1:3000":
            titleText += " (Local)"
            break;
        case "survey-stage.post.pivital.com":
            titleText += " (Stage)"
            break;
        case "survey.post.pivital.com":
            titleText += " (Prod)"
            break;
        case "survey-demo.post.pivital.com":
            titleText += " (Demo)"
            break;
        default:
            break;
    }
    // let titleString = "POST" +

    return (
            <div>
                <AppBar position="static">
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit" component="div">
                            {titleText}
                        </Typography>
                        {/*<img src={"/Artboard1.png"} alt={"logo"} style={{height: '40px'}} />*/}
                        <Box sx={{ flexGrow: 1 }}></Box>


                        {(userToken === null || userToken === undefined)  && (<>
                            <Button onClick={() => {
                                // setShowLogin(true)
                                setMode(UnauthenticatedViewModalMode.Login);
                            }} color="secondary">Login</Button>
                        </>)}
                        {/*{(userToken === null || userToken === undefined)  && (<>*/}
                        {/*    <Button onClick={() => {*/}
                        {/*        setShowRegister(true)*/}
                        {/*    }} color="secondary">Register</Button>*/}
                        {/*</>)}*/}
                        {userToken !== undefined && userToken !== null && (<>
                            {/*<Link to="/dashboard/">Dashboard</Link>*/}
                            <Button onClick={() => {
                                // setShowLogin(true)
                                navigate('/dashboard');
                            }}  color="secondary">Dashboard</Button>
                        </>)}
                    </Toolbar>
                </AppBar>

                <Grid container spacing={0}>



                    <Grid item xs>
                        <Paper elevation={1} style={{
                            margin: '1rem', height: 'calc( var(--app-height) - 2rem - 48px )', width:"calc ( 100% - 2rem )", padding: '1rem',
                            // background: 'IMG_6955.jpeg'
                            // backgroundImage: `url("IMG_6955.jpeg")`,
                            // backgroundPosition: 'center',
                            // backgroundSize: 'cover',
                            // backgroundRepeat: 'no-repeat'
                        }}>
                            {/*<ScrollPane>*/}
                            <Paper elevation={2} style={{ padding: '1rem',
                                height: 'calc( 100% )', overflow: 'auto'
                            }}>
                                <Stack style={{textAlign: "center", width: '100%'}}>

                                    <Typography variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                                        POST@Pivital
                                    </Typography>




                                    {/*<PricingSubView />*/}

                                </Stack>

                            </Paper>
                            <br />


                            {/*<br/>*/}
                            {/*<img*/}
                            {/*    src={`IMG_6955.jpeg`}*/}
                            {/*    loading="lazy"*/}
                            {/*    width="100%"*/}
                            {/*/>*/}
                            {/*<br/>*/}

                            {/*<EmailSubscriptionBox />*/}
                            {/*</ScrollPane>*/}

                        </Paper>
                    </Grid>

                </Grid>


                <Modal
                    open={mode !== UnauthenticatedViewModalMode.Hidden}
                    onClose={() => { setMode(UnauthenticatedViewModalMode.Hidden) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        {mode === UnauthenticatedViewModalMode.Login && (<LoginModal openAccountRecovery={() => { setMode(UnauthenticatedViewModalMode.AccountRecovery) }}/>)}
                        {mode === UnauthenticatedViewModalMode.AccountRecovery && (<AccountRecoveryModal closeAccountRecovery={ () => { setMode(UnauthenticatedViewModalMode.Hidden) } }/>)}
                    </>}
                </Modal>

                {/*<AccountCreationModal open={showRegister} onClose={() => setShowRegister(false)} />*/}

            </div>
    );

}





export default UnauthenticatedMainView;