// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {
    DataGrid,
    GridCellParams, GridRowId,
    GridRowParams,
    GridValueGetterParams
} from '@mui/x-data-grid';
import {DataGridPro} from '@mui/x-data-grid-pro';
import AuthenticatedLayout, {AuthenticatedLayoutCustomButton} from "../../Library/AuthenticatedLayout";
import {
    CustomFormField, ICondition,
    IQuickObject,
    ISurvey,
    IUser,
    SurveyObject, SurveyObjectQuestion,
} from "../../types/interfaces";
import {SurveyDataController} from "./SurveyDataController";
import {AuthContext} from "../../Library/AuthContext";
import {
    Box, Button,
    IconButton, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Modal, Paper,
    Stack, Switch, Tab, Tabs, TextField,
    Tooltip, Typography,
} from "@mui/material";

import {EditObject} from "../../Shared/Components/EditObject";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {useParams} from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import UpArrowIcon from '@mui/icons-material/ArrowUpward';
import DownArrowIcon from '@mui/icons-material/ArrowDownward';

import {
    faArrowAltCircleDown,
    faArrowAltCircleUp, faBell, faCircleCheck,
    faCopy, faMagnifyingGlass, faPencil, faPlus, faSave, faTrash, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {UserDataController} from "../Users/UserDataController";
import {showAlertAsync} from "../../Shared/CustomPrompt";
import {UploadOutlined as UploadOutlineIcon} from "@mui/icons-material";
import {BankDataController_QuickObject} from "../Bank/BankDataController_QuickObject";
import {AddNewObject} from "./SurveyEditor/ObjectViews";
import {ContentAccordionDetail} from "./SurveyEditor/ContentAccordionDetail";
import {faObjectGroup, faObjectUngroup} from "@fortawesome/free-regular-svg-icons";
import {EditQuestion} from "./SurveyEditor/QuestionView";
import {DateTimePicker} from "@mui/x-date-pickers";
// import moment from "moment/moment";
import moment from 'moment-timezone';
// https://mui.com/material-ui/icons/#font-awesome
export function generateCode() {
    return (Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)).toUpperCase();
}


// function FlashingFloatingActionSaveButton() {
//
//     return (<>
//         <Fab variant="extended" color="primary" aria-label="Save">
//             <SaveSharp />
//             Save
//         </Fab>
//     </>);
// }

function EditQuestionButtonAndModalFactory(survey: ISurvey, setSurvey: (survey: ISurvey) => void, onClose: () => void) {
    return function EditQuestionButtonAndModal(params: GridCellParams) {

        const [open, setOpen] = React.useState<boolean>(false);

        // let row = params.row as any;

        // let question = survey.content.find((item) => item.key === params.row.parent)?.questions.find((item) => item.key === params.row.key);

        let question = React.useMemo(() => {
            return survey.content.find((item) => item.key === params.row.parent)?.questions.find((item) => item.key === params.row.key);
        }, [params.row.key, params.row.parent]);

        let surveyObject = React.useMemo(() => {
            return survey.content.find((item) => item.key === params.row.parent);
        }, [params.row.parent]);

        // let surveyObject = survey.content.find((item) => item.key === question.parent);
        // let surveyObject = survey.content.find((item) => item.key === question.parent);
        return (<>
            <Tooltip title="Edit Question" arrow><IconButton color={"primary"} onClick={() => {
                setOpen(true);
            }}><FontAwesomeIcon icon={faPencil}/></IconButton></Tooltip>
            {open && surveyObject !== undefined && question !== undefined &&  (<>
                <EditQuestion
                    survey={survey}
                    question={question}
                    surveyObject={surveyObject}
                    open={open} onClose={() =>{ setOpen(false) }} saveQuestion={(questionToSave: SurveyObjectQuestion) => {

                    // content = [...survey.content];
                    let content =  [...survey.content].map((item) => {
                        if (item.key === params.row.parent) {
                            let questions = [...item.questions];
                            questions = questions.map((question) => {
                                console.log(question.key, params.row.key)
                                if (question.key === params.row.key) {
                                    return questionToSave;
                                }
                                return question;
                            });
                            return {...item, questions};
                        }
                        return item;
                    })
                    setSurvey({...survey, content});
                    onClose(); // This is a work around, since it won't repull the survey when the content changes.

                    return true;
                    // return false;
                }} />
            </>)}
        </>)
    }
}

function DeleteQuestionButtonAndConfirmFactory(survey: ISurvey, setSurvey: (survey: ISurvey) => void, onClose: () => void) {
    return function DeleteQuestionButtonAndConfirm(params: GridCellParams) {

        return (<>
            <Tooltip title="Delete Question" arrow><IconButton color={"error"} onClick={() => {
                if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                    let content = [...survey.content].map((item) => {
                        if (item.key === params.row.parent) {
                            let questions = [...item.questions].filter((question) => question.key !== params.row.key);
                            return {...item, questions};
                        }
                        return item;
                    })
                    setSurvey({...survey, content});
                    onClose(); // This is a work around, since it won't repull the survey when the content changes.
                }
            }}><DeleteIcon /></IconButton></Tooltip>
        </>)

    }
}

export const SearchQuestionModal = ({ open, onClose, survey, setSurvey } : { open: boolean, onClose: () => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void }) => {

    const [item, setItem] = React.useState<any>({ filter: "" });
    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "filter",
                    label: "Filter",
                }
            ];


            return fields;
        },
        []
    );

    const questionsMemo = React.useMemo(() => {
        let questions: any[] = [];
        if (survey === null || survey === undefined) {
            return questions;
        }
        survey.content.forEach((item) => {
            item.questions.forEach((question) => {
                questions.push({id: item.key + "-" + question.key, ...question, parent: item.key});
            });
        });

        if (item.filter === null || item.filter === undefined || item.filter === "") {
            return questions;
        }
        questions = questions.filter((question) => question.questionText.toLowerCase().includes(item.filter.toLowerCase()));
        return questions;

        // let questions = survey.content.filter((item) => item.questions.length > 0);
        // return questions;
    }, [survey, item]);






    const columns = React.useMemo(
        () => [
            { field: 'id', headerName: 'ID', width: 300 },
            { field: 'parent', headerName: 'Parent Key', width: 200 },
            {
                field: 'parent_name',
                headerName: 'Parent Name',
                width: 200,
                valueGetter: (params: GridValueGetterParams) => {
                    let obj = params.row as any;
                    let parent = survey.content.find((item) => item.key === obj.parent);
                    if (parent === null || parent === undefined) {
                        return "";
                    }
                    return parent.location;
                }
            },
            { field: 'key', headerName: 'Question Key', width: 200 },
            // { field: 'debug', headerName: 'Debug', width: 1800, valueGetter: (params: GridValueGetterParams) => {return JSON.stringify(params.row);}},
            // { field: 'location', headerName: 'Object Name', width: 200 },
            { field: 'questionText', headerName: 'Question Text', width: 200 },

            { field: 'edit_btn', headerName: '', width: 75, renderCell: EditQuestionButtonAndModalFactory(survey, setSurvey, onClose) },
            { field: 'delete_btn', headerName: '', width: 75, renderCell: DeleteQuestionButtonAndConfirmFactory(survey, setSurvey, onClose) },

            // { field: 'parent', headerName: "Parent", width: 200, valueGetter: Column_ValueGetter_Parent(item) },
            // { field: 'count', headerName: "Questions Count", width: 150, valueGetter: Column_ValueGetter_Count() },
            // { field: 'clone_btn', headerName: '', width: 75, renderCell: Column_RenderCell_CloneBtn(item) },
        ],
        [onClose, setSurvey, survey],
    );

    return (<>
        <Modal open={open} onClose={() => {
            onClose();
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Stack>


                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Search Questions
                    </Typography>

                    <EditObject
                        item={item}
                        setItem={setItem}
                        form={fields}
                        columns={12}
                    />
                    {/*<EditObject*/}
                    {/*    item={item}*/}
                    {/*    setItem={setItem}*/}
                    {/*    form={fields}*/}
                    {/*    columns={12}*/}
                    {/*/>*/}
                    <br />

                    <DataGrid
                        // make sure to set height to 100% so that the grid fills the whole container
                        style={{ height: '100%', minHeight: '400px' }}
                        rows={questionsMemo}
                        columns={columns}
                        // getRowId={(row: GridValidRowModel) => row._id}
                        pageSizeOptions={[200]}
                        disableRowSelectionOnClick
                    />

                </Stack>
            </Box>
        </Modal>
    </>);
};

export const SurveyDateTimePassthru = (props : { survey: ISurvey, setSurvey: (survey: ISurvey) => void, field: CustomFormField }) => {

    const googleMapsApiKey = "AIzaSyD4xGi4lv-qyiAtNz2iv2pGGoyQrEqYKkY";

    async function getCoordinates(address: string): Promise<{lat: number, lng: number} | null> {
        const geocodeApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=` + googleMapsApiKey;

        try {
            const response = await fetch(geocodeApiUrl);
            const data = await response.json();

            if (data.status === "OK") {
                return {
                    lat: data.results[0].geometry.location.lat,
                    lng: data.results[0].geometry.location.lng
                };
            } else {
                console.error("Geocoding failed:", data.status);
                return null;
            }
        } catch (error) {
            console.error("Error fetching geocode data:", error);
            return null;
        }
    }

    async function getTimezone(lat: number, lng: number): Promise<string | null> {
        const timestamp = Math.floor(Date.now() / 1000);
        const timezoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=` + googleMapsApiKey;

        try {
            const response = await fetch(timezoneApiUrl);
            const data = await response.json();

            if (data.status === "OK") {
                return data.timeZoneId;
            } else {
                console.error("Timezone fetch failed:", data.status);
                return null;
            }
        } catch (error) {
            console.error("Error fetching timezone data:", error);
            return null;
        }
    }

    const [timezone, setTimezone] = React.useState<string | null>(null);
    // const [currentTime, setCurrentTime] = React.useState<Date | null>(null);

    const [currentDate, setCurrentDate] = React.useState(new Date());

    // Update the date in an effect if needed
    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000); // Update every second, adjust as needed

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        async function loadData() {
            if (props.survey === null || props.survey === undefined) {
                console.log("survey is null")
                setTimezone(null)
                return;
            }

            let coordinates = props.survey.address;
            if (coordinates === null || coordinates === undefined || coordinates === "") {
                console.log("coordinates is null")
                setTimezone(null)
                return;
            }

            let coords = await getCoordinates(coordinates);
            if (coords === null || coords === undefined) {
                console.log("coords is null")
                setTimezone(null)
                return;
            }

            let timezone = await getTimezone(coords.lat, coords.lng);
            if (timezone === null || timezone === undefined) {
                console.log("timezone is null")
                setTimezone(null)
                return;
            }
            console.log(timezone)

            setTimezone(timezone);
        }
        loadData().then();
    }, [props.survey]); // props.survey.address

    function GetSetTime() {
        if (props.survey === null || props.survey === undefined) {
            return null;
        }
        let date = props.survey[props.field.key as keyof ISurvey] as unknown as Date;
        if (date === null || date === undefined) {
            return null;
        }
        return moment(date);
    }


    return (<>
        {props.survey !== null && props.survey !== undefined && (<>
            <Stack alignItems="center">
                <DateTimePicker
                    label={props.field.label}
                    value={ GetSetTime() }
                    onChange={(date) => {
                        let newItem = { ...props.survey };
                        newItem[props.field.key as keyof ISurvey] = date as unknown as ISurvey[keyof ISurvey];
                        props.setSurvey(newItem);
                    }}
                    slotProps={{
                        textField: {
                            fullWidth: true,
                            size: GetSetTime() !== null && GetSetTime() !== undefined ? "small" : "medium"
                        },
                    }}

                    // helperText={"This is the date the survey was taken."}
                    disabled={props.field.readonly}
                />
                {GetSetTime() !== null && GetSetTime() !== undefined && (
                    <>
                        <Typography variant="body2" style={{ textAlign: 'center', opacity: '0.7' }}>
                            { GetSetTime()?.utc().format('LLLL z') }
                        </Typography>
                    </>
                )}
                {GetSetTime() !== null && GetSetTime() !== undefined && timezone !== null && (
                    <>
                        <Typography variant="body2" style={{ textAlign: 'center', opacity: '0.7' }}>
                            Site Local Time: { moment(GetSetTime()).tz(timezone).format('LLLL z') }
                        </Typography>
                    </>
                )}
                {GetSetTime() !== null && GetSetTime() !== undefined && timezone !== null && (
                    <>
                        <Typography variant="body2" style={{ textAlign: 'center', opacity: '0.7' }}>
                            Current Site Local Time: { moment(currentDate).tz(timezone).format('LLLL z') }
                        </Typography>
                    </>
                )}

            </Stack>
        </>)}
    </>);
}


export const ConditionFlagsPassthru = (props : { survey: ISurvey, setSurvey: (survey: ISurvey) => void, field: CustomFormField }) => {

    const [open, setOpen] = React.useState<boolean>(false);
    const [newConditionFlag, setNewConditionFlag] = React.useState<string>("");

    return (<>
        <Button type={"button"} variant={"outlined"} size={"large"} fullWidth={true} onClick={() => {
            setOpen(true)
        }}>Condition Flags</Button>
        <Modal open={open} onClose={() => {
            setOpen(false);
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    // width: '60%',
                    width: '600px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Condition Flags
                </Typography>

                {props.survey.conditionFlags !== undefined && (
                    <>
                        <Paper elevation={1}>
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                subheader={<ListSubheader>Condition Flags</ListSubheader>}
                            >
                                {Object.keys(props.survey.conditionFlags).map((item) => {
                                    return (<>
                                        <ListItem>
                                            <ListItemText id="switch-list-label-wifi" primary={item} />

                                            <ListItemIcon>
                                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                                    // prompt to delete
                                                    if(window.confirm(`Are you sure you want to delete ${item}?`) === false) {
                                                        return;
                                                    }

                                                    let newItem = { ...props.survey };
                                                    if (newItem.conditionFlags === undefined) {
                                                        newItem.conditionFlags = {};
                                                    }
                                                    delete newItem.conditionFlags[item];
                                                    // newItem.conditionFlags[item] = !newItem.conditionFlags[item];
                                                    props.setSurvey(newItem);
                                                    // newItem.conditionFlags = newItem.conditionFlags.filter((flag) => flag !== item);
                                                    // props.setSurvey(newItem);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemIcon>

                                            <Switch
                                                edge="end"
                                                onChange={() =>{
                                                    let newItem = { ...props.survey };
                                                    if (newItem.conditionFlags === undefined) {
                                                        newItem.conditionFlags = {};
                                                    }
                                                    newItem.conditionFlags[item] = !newItem.conditionFlags[item];
                                                    props.setSurvey(newItem);
                                                    // newItem.conditionFlags = newItem.conditionFlags.filter((flag) => flag !== item);
                                                    // props.setSurvey(newItem);
                                                }}
                                                checked={props.survey.conditionFlags === undefined ? false: props.survey.conditionFlags[item]}
                                                inputProps={{
                                                    'aria-labelledby': 'switch-list-label-wifi',
                                                }}
                                            />

                                        </ListItem>
                                    </>)
                                })}
                            </List>
                        </Paper>
                    </>
                )}

                <Paper elevation={1}>

                    <Stack direction={"row"} spacing={1} sx={{padding: 2}}>
                        <TextField
                            sx={{ minWidth: '400px' }}
                            fullWidth={true}
                            type={"text"}
                            label={"New Condition Flag"}
                            value={newConditionFlag}
                            onChange={(e) => {
                                setNewConditionFlag(e.target.value);
                            }}
                        ></TextField>
                        <Button type={"button"} variant={"outlined"} size={"large"} disabled={newConditionFlag === ""} fullWidth={true} onClick={() => {
                            let newItem = { ...props.survey };
                            if (newItem.conditionFlags === undefined) {
                                newItem.conditionFlags = {};
                            }
                            newItem.conditionFlags[newConditionFlag] = false;
                            // newItem.conditionFlags[item] = !newItem.conditionFlags[item];
                            props.setSurvey(newItem);
                            setNewConditionFlag("");
                        }}>Create</Button>
                    </Stack>
                </Paper>



            </Box>
        </Modal>
    </>);
}

// export const SurveySiteAddressEditorPassthru = (props : { survey: ISurvey, setSurvey: (survey: ISurvey) => void, field: CustomFormField }) => {
//     const googleMapsApiKey = "AIzaSyD4xGi4lv-qyiAtNz2iv2pGGoyQrEqYKkY";
//
//     async function getCoordinates(address: string): Promise<{lat: number, lng: number} | null> {
//         const geocodeApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=` + googleMapsApiKey;
//
//         try {
//             const response = await fetch(geocodeApiUrl);
//             const data = await response.json();
//
//             if (data.status === "OK") {
//                 return {
//                     lat: data.results[0].geometry.location.lat,
//                     lng: data.results[0].geometry.location.lng
//                 };
//             } else {
//                 console.error("Geocoding failed:", data.status);
//                 return null;
//             }
//         } catch (error) {
//             console.error("Error fetching geocode data:", error);
//             return null;
//         }
//     }
//
//     async function getTimezone(lat: number, lng: number): Promise<string | null> {
//         const timestamp = Math.floor(Date.now() / 1000);
//         const timezoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=` + googleMapsApiKey;
//
//         try {
//             const response = await fetch(timezoneApiUrl);
//             const data = await response.json();
//
//             if (data.status === "OK") {
//                 return data.timeZoneId;
//             } else {
//                 console.error("Timezone fetch failed:", data.status);
//                 return null;
//             }
//         } catch (error) {
//             console.error("Error fetching timezone data:", error);
//             return null;
//         }
//     }
//
//     const [timezone, setTimezone] = React.useState<string | null>(null);
//
//     React.useEffect(() => {
//         async function loadData() {
//             if (props.survey === null || props.survey === undefined) {
//                 console.log("survey is null")
//                 setTimezone("cannot load timezone")
//                 return;
//             }
//
//             let coordinates = props.survey.address;
//             if (coordinates === null || coordinates === undefined || coordinates === "") {
//                 console.log("coordinates is null")
//                 setTimezone("cannot load timezone")
//                 return;
//             }
//
//             let coords = await getCoordinates(coordinates);
//             if (coords === null || coords === undefined) {
//                 console.log("coords is null")
//                 setTimezone("cannot load timezone")
//                 return;
//             }
//
//             let timezone = await getTimezone(coords.lat, coords.lng);
//             if (timezone === null || timezone === undefined) {
//                 console.log("timezone is null")
//                 setTimezone("cannot load timezone")
//                 return;
//             }
//             console.log(timezone)
//
//             setTimezone(timezone);
//         }
//         loadData().then();
//     }, [props.survey]);
//
//     return (<>
//         <Stack>
//             {props.survey !== null && props.survey !== undefined && (<>
//                 Timezone: {timezone}
//                 {/*<TextField*/}
//                 {/*    fullWidth={true}*/}
//                 {/*    type={"text"}*/}
//                 {/*    // size={"small"}*/}
//                 {/*    label={props.field.label}*/}
//                 {/*    value={props.survey[props.field.key as keyof ISurvey]}*/}
//                 {/*    onChange={(e) => {*/}
//                 {/*        let newItem = {...props.survey};*/}
//                 {/*        newItem[props.field.key as keyof ISurvey] = e.target.value as unknown as ISurvey[keyof ISurvey];*/}
//                 {/*        props.setSurvey(newItem);*/}
//                 {/*    }}*/}
//                 {/*    disabled={props.field.readonly}*/}
//                 {/*    // InputProps={{*/}
//                 {/*    //     endAdornment: endAdornment,*/}
//                 {/*    // }}*/}
//                 {/*    InputLabelProps={{ shrink: (props.survey[props.field.key as keyof ISurvey] !== '' && props.survey[props.field.key as keyof ISurvey] !== null && props.survey[props.field.key as keyof ISurvey] !== undefined) }}*/}
//                 {/*/>*/}
//             </>)}
//         </Stack>
//     </>);
// }

function RenderCellValidInvalidIcon(props: { survey: ISurvey, params: GridCellParams}) {


    const row = props.params.row as SurveyObject;

    const warningsMemo = React.useMemo(() => {

        let warnings: string[] = [];

        // this should work for the object level.
        row.questions.forEach((question: SurveyObjectQuestion) => {
            console.log(`question: ${JSON.stringify(question)}`);
            if (question.conditions !== undefined && question.conditions !== null) {
                question.conditions.forEach((condition: ICondition) => {
                    let selectedQuestions = row.questions.filter((q) => q.key === condition.key)[0];
                    if (selectedQuestions === undefined) {
                        warnings.push(`Question ${row.key} - ${question.key} is missing.`);
                    }
                })
            }

            // if (question.requiredConditionFlags !== undefined && question.requiredConditionFlags !== null) {
            //     question.requiredConditionFlags.forEach((flag: string) => {
            //         if (props.survey.conditionFlags === undefined || props.survey.conditionFlags === null || props.survey.conditionFlags[flag] === undefined || props.survey.conditionFlags[flag] === null) {
            //             warnings.push(`Question ${row.key} is missing condition flag: ${flag}.`);
            //         }
            //     })
            // }
        })

        return warnings
    }, [row.questions, props.survey.conditionFlags])

    const warningsTitleMemo = React.useMemo(() => {
        if (warningsMemo.length === 0) {
            return "No Warnings";
        }

        return (<>
            {warningsMemo.map((warning) => {
                return (<>{warning}<br /></>)
            })}
        </>)
    }, [warningsMemo])

    return (<>
        <Tooltip title={
            warningsTitleMemo
        } >
            <FontAwesomeIcon
                icon={warningsMemo.length === 0 ? faCircleCheck : faTriangleExclamation}
                style={{
                    color: warningsMemo.length === 0 ? 'green' : 'red',
                    fontSize: '2em'
                }}
            />
        </Tooltip>
    </>)
}

function SurveyEditorView() {

    const { id } = useParams<{ id: string }>();

    const [addNewObjectModalShown, setAddNewObjectModalShown] = React.useState<boolean>(false)

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [survey, internal_setSurvey] = React.useState<ISurvey>();
    const [saveRequired, setSaveRequired] = React.useState<boolean>(false);
    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])

    const setSurvey = React.useCallback((item: ISurvey) => {
        internal_setSurvey(item);
        setSaveRequired(true);
    }, [internal_setSurvey]);

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "caseNumber",
                    label: "Case Number"
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "assigned_user_id",
                    label: "Assigned User Id",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                    ]
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "coordinator_user_id",
                    label: "Coordinator",
                    options: [
                        { label: "Un-assigned", value: null },
                        ...coordinatorsList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
                    ]
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "surveyName",
                    label: "Survey Name"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "customerTicketNumber",
                    label: "Customer Ticket Number"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactName",
                    label: "Site Contact Name"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactPhone",
                    label: "Site Contact Phone"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.TWO,
                    key: "siteContactEmail",
                    label: "Site Contact Email"
                },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "companyName",
                    label: "Company Name"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.SMALL,
                    key: "projectName",
                    label: "Project Name"
                },
                // {
                //     kind: CustomFormFieldTypes.DATETIME,
                //     size: CustomFormFieldSizes.SMALL,
                //     key: "surveyDate",
                //     label: "Survey Date"
                // },

                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.ONE,
                    key: "surveyOwner",
                    label: "Survey Owner"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.MEDIUM,
                    key: "address",
                    label: "Site Address"
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.THREE,
                    key: "surveyDate",
                    label: "Survey Date",
                    passthru: SurveyDateTimePassthru,
                    passthruProps: { survey: survey, setSurvey: setSurvey }
                },
                {
                    kind: CustomFormFieldTypes.PASSTHRU,
                    size: CustomFormFieldSizes.ONE,
                    key: "conditionFlags",
                    label: "Condition Flags",
                    passthru: ConditionFlagsPassthru,
                    passthruProps: { survey: survey, setSurvey: setSurvey }
                },
                // {
                //     kind: CustomFormFieldTypes.PASSTHRU,
                //     size: CustomFormFieldSizes.MEDIUM,
                //     key: "address_timezone",
                //     label: "Site Address",
                //     passthru: SurveySiteAddressEditorPassthru,
                //     passthruProps: { survey: survey, setSurvey: setSurvey }
                // }
            ];


            // TODO: add question instructions (possibly a toggle for it)
            // TODO: also add option for default value

            return fields;
        },
        [usersList, coordinatorsList, setSurvey, survey] // survey?.address, survey?.surveyDate,
    );


    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken} id: ${id}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            if (id === null || id === undefined) {
                return;
            }

            try {
                let item = await new SurveyDataController(userToken).getOne(id);
                if (item === null || item === undefined) {
                    alert("Error loading");
                    return;
                }
                internal_setSurvey(item);
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken, id]);

    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken} id: ${id}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            if (id === null || id === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT));
                setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken, id]);

    const handleSave = React.useCallback(async () => {
        if (survey === null || survey === undefined) return;
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (id === null || id === undefined) {
            return;
        }
        try {
            console.log(survey)
            let response = await new SurveyDataController(userToken).update(id, survey);
            console.log(response);
            if (response === null || response === undefined) {
                alert("Error saving");
                return;
            }
            internal_setSurvey(response); // setting the values to this, causes the fields to go into a weird mode.
            setSaveRequired(false);
            // alert("Saved");
            await showAlertAsync({title: "Survey Saved", message:"Saved Successfully"}).then();
        }
        catch (e) {
            console.warn(`exception: ${(e as any).message}`)
        }
    }, [survey, userToken, id, internal_setSurvey, setSaveRequired]);

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => (<>
            {survey !== null && survey !== undefined && userToken !== null && userToken !== undefined &&
                <ContentAccordionDetail  survey={survey} setItem={(updatedItem) => {
                    let content = [...survey.content];
                    let index = content.findIndex((item) => item.key === updatedItem.key);
                    content[index] = updatedItem;
                    let clone = {...survey, content};
                    setSurvey(clone);
                }} item={row as SurveyObject} setSurvey={setSurvey} userToken={userToken}/>
            }
        </>),
        [survey, setSurvey, userToken],
    );

    function Column_ValueGetter_Parent(survey: ISurvey): (params: GridValueGetterParams) => string {
        return function (params: GridValueGetterParams) : string {
            let obj = params.row as SurveyObject;
            let parentKey = obj.parent;
            if (parentKey === null || parentKey === undefined || parentKey === "") {
                return "";
            }
            let parent = survey.content.find((item) => item.key === parentKey);
            if (parent === null || parent === undefined) {
                return "";
            }
            return parent.location + " (" + parent.key + ")";
        }
    }


    function Column_ValueGetter_Count(): (params: GridValueGetterParams) => string {
        return function (params: GridValueGetterParams) : string {
            let obj = params.row as SurveyObject;
            return obj.questions.length.toString();
        }
    }


    function Column_RenderCell_CloneBtn(survey: ISurvey) {
        return function (params: GridCellParams) {
            let obj = params.row as SurveyObject;
            let index = survey.content.findIndex((item) => item.key === obj.key);
            return (<Tooltip title="Clone Object" arrow><IconButton color={"primary"} onClick={() => {
                let content = [...survey.content];
                let temp = content[index];
                let key = generateCode(); // Must make sure its unique.
                let clone = {...temp, location: temp.location , key: key};
                content.push(clone);
                setSurvey({...survey, content});
            }}> <FontAwesomeIcon icon={faCopy} />  </IconButton></Tooltip>);
        }
    }

    React.useEffect(() => {
        window.onbeforeunload = saveRequired
            ? (event: BeforeUnloadEvent) => {
                return "Please save before closing the page";
            }
            : null;
    }, [saveRequired]);

    const [headerExpanded, setHeaderExpanded] = React.useState<boolean>(true);
    const [showQuestionsSearch, setShowQuestionsSearch] = React.useState<boolean>(false);

    // these buttons didn't allow the save to work properly.?????
    const customButtonsMemo = React.useMemo<AuthenticatedLayoutCustomButton[]>(() => {
        let buttons: AuthenticatedLayoutCustomButton[] = [];

        // if (!saveRequired) {
        //     // send push notification button
        //     buttons.push({
        //         icon: <FontAwesomeIcon icon={faBell}/>,
        //         label: "Push to Tech",
        //         action: () => {
        //
        //         },
        //         disabled: survey?.assigned_user_id === null || survey?.assigned_user_id === undefined
        //     });
        // }

        // Add button

        buttons.push({
            icon: <FontAwesomeIcon icon={faPlus} />,
            label: "Add",
            action: () => {
                setAddNewObjectModalShown(true)
            }
        });

        if (saveRequired) {
            // Save button
            buttons.push({
                icon:  <FontAwesomeIcon icon={faSave} />,
                label: "Save",
                action: () => {
                    console.log("save clicked")
                    handleSave().then(() => {})
                }
            });

            // Save button
            buttons.push({
                icon:  <FontAwesomeIcon icon={faTrash} />,
                label: "Don't Save",
                action: () => {
                    setSaveRequired(false)
                }
            });
        }

        // Expand button
        buttons.push({
            icon:  <FontAwesomeIcon icon={headerExpanded ? faObjectUngroup : faObjectGroup} />,
            label: (headerExpanded ? "Collapse" : "Expand"),
            action: () => {
                setHeaderExpanded(!headerExpanded)
            }
        });

        buttons.push({
            icon: <FontAwesomeIcon icon={faMagnifyingGlass} />,
            label: "Search",
            action: () => {
                setShowQuestionsSearch(true)
            }
        });

        return buttons;

    }, [ saveRequired, handleSave, headerExpanded ]);


    // This forces it to have only one expanded row at a time.
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
        GridRowId[]
    >([]);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[]) => {
            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );
        },
        [],
    );
    // end of forcing only one expanded row at a time.

    return (
        <div>

            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout
                pageTitle={"Survey Editor" + (saveRequired ? "*" : "")}
                saveRequired={saveRequired}
                customButtons={customButtonsMemo}
            >
                <Stack sx={{ height: '100%'}}>
                {/*<div style={{ height: 'calc( 100vh - 13rem )' }}>*/}
                    {headerExpanded && (
                        <EditObject item={survey} setItem={setSurvey} form={fields} columns={12}></EditObject>
                    )}


                    <br />

                    {survey !== null && survey !== undefined && survey.content !== null &&
                        survey.content !== undefined && survey.content.length > 0 && (<>
                    {/*    11 rem    */}
                    <DataGridPro
                        style={{ height: (headerExpanded) ? 'calc( 100% - 20rem )' : 'calc( 100% - 2rem )', width: '100%' }}
                        columns={[
                            { field: 'valid', headerName: '', width: 50, renderCell:  (params: GridCellParams) => (<><RenderCellValidInvalidIcon survey={survey} params={params}  /></>) },
                            { field: 'key', headerName: 'Key', width: 200 },
                            { field: 'location', headerName: 'Object Name', width: 200 },
                            { field: 'isConditional', headerName: 'Is Conditional', width: 130 },
                            { field: 'parent', headerName: "Parent", width: 200, valueGetter: Column_ValueGetter_Parent(survey) },
                            { field: 'count', headerName: "Questions Count", width: 150, valueGetter: Column_ValueGetter_Count() },
                            { field: 'clone_btn', headerName: '', width: 75, renderCell: Column_RenderCell_CloneBtn(survey) },

                            { field: 'move_to_top_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                let obj = params.row as SurveyObject;
                                let index = survey.content.findIndex((item) => item.key === obj.key);
                                return (<Tooltip title="Move Object To Top" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
                                    let content = [...survey.content];
                                    let temp = content[index];
                                    content.splice(index, 1);
                                    content.unshift(temp);
                                    setSurvey({...survey, content});
                                }}>
                                    <FontAwesomeIcon icon={faArrowAltCircleUp} />
                                </IconButton></Tooltip>)
                            }},

                            { field: 'move_up_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                    let obj = params.row as SurveyObject;
                                    let index = survey.content.findIndex((item) => item.key === obj.key);
                                    return (<Tooltip title="Move Object Up" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
                                        let content = [...survey.content];
                                        let temp = content[index];
                                        content[index] = content[index - 1];
                                        content[index - 1] = temp;
                                        setSurvey({...survey, content});
                                    }}><UpArrowIcon /></IconButton></Tooltip>)
                                }},

                            { field: 'move_down_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                    let obj = params.row as SurveyObject;
                                    let index = survey.content.findIndex((item) => item.key === obj.key);
                                    return (<Tooltip title="Move Object Down" arrow><IconButton disabled={index === survey?.content.length - 1} color={"warning"} onClick={() => {
                                        let content = [...survey.content];
                                        let temp = content[index];
                                        content[index] = content[index + 1];
                                        content[index + 1] = temp;
                                        setSurvey({...survey, content});
                                    }}><DownArrowIcon /></IconButton></Tooltip>)
                                }},

                            { field: 'move_to_bottom_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                    let obj = params.row as SurveyObject;
                                    let index = survey.content.findIndex((item) => item.key === obj.key);
                                    return (<Tooltip title="Move Object To Bottom" arrow><IconButton disabled={index === survey?.content.length - 1} color={"warning"} onClick={() => {
                                        let content = [...survey.content];
                                        let temp = content[index];
                                        content.splice(index, 1);
                                        content.push(temp);
                                        setSurvey({...survey, content});
                                    }}>
                                        <FontAwesomeIcon icon={faArrowAltCircleDown} />
                                    </IconButton></Tooltip>)
                                }},

                            { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                    return (<Tooltip title="Delete Object" arrow><IconButton color={"error"} onClick={() => {
                                        if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                                            let item = params.row as SurveyObject;
                                            let content = [...survey.content].filter((obj) => obj.key !== item.key);
                                            setSurvey({...survey, content});
                                        }
                                    }}><DeleteIcon /></IconButton></Tooltip>)
                                }},

                            { field: 'export_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                                return (<Tooltip title={"Export Object to Bank"}><IconButton color={"secondary"} onClick={() => {
                                    if (userToken === null || userToken === undefined) {
                                        alert("You must be logged in to export.");
                                        return;
                                    }
                                    let objectToCreate: IQuickObject = {
                                        content : params.row as SurveyObject,
                                    } as IQuickObject;
                                    new BankDataController_QuickObject(userToken).create(objectToCreate).then((response) => {
                                        alert("Exported successfully.");
                                    });

                                }}><UploadOutlineIcon /></IconButton></Tooltip>)
                            }},
                        ]}
                        rows={survey.content}
                        rowThreshold={0}
                        getRowId={(row) => row.key || row.location}
                        getDetailPanelHeight={(row) => {
                            // row.row
                            let questionCount = (row.row as SurveyObject).questions.length;
                            if (questionCount === 0) {
                                return 355; // was 285
                            }
                            // 350
                            return 490 + ( (row.row as SurveyObject).questions.length * 50); // WAS 450
                        }}
                        getDetailPanelContent={getDetailPanelContent}
                        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                        disableRowSelectionOnClick
                        // checkboxSelection={mode === Mode.BulkSelect} // this isn't where we want bulk select. We want it on the questions level.
                    />
                    </>)}
                {/*</div>*/}
                </Stack>

                <AddNewObject
                    onAddNewObject={(obj) => {
                        if (survey === undefined) {
                            return false;
                        }
                        let objects: SurveyObject[] = [...survey.content, obj]
                        console.log(`item: ${JSON.stringify(objects)}, question: ${JSON.stringify(obj)}`);
                        setSurvey({...survey, content: objects});
                        return true;
                    }}
                    onClose={() => setAddNewObjectModalShown(false)}
                    open={addNewObjectModalShown}
                />

                {/* This isn't triggering a change on survey change */}
                {showQuestionsSearch && survey !== null && survey !== undefined && survey.content && userToken !== null && userToken !== undefined && (
                    <SearchQuestionModal
                        onClose={() => {setShowQuestionsSearch(false)}}
                        open={showQuestionsSearch}
                        survey={survey}
                        setSurvey={setSurvey}
                    />
                )}


            </AuthenticatedLayout>

        </div>
    );
}

export default SurveyEditorView;
