import React from 'react';
import './App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import UnauthenticatedMainView from "./Views/UnauthenticatedMainView";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ProtectedRoute, {AuthenticationStatus, ProtectedRouteProps} from "./Views/PrivateRoute";
import {useLocalStorage} from "./Hooks/useLocalStorage";
import {orange} from "@mui/material/colors";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import UserManagementView from "./Modules/Users/UserManagementView";
import {IUser} from "./types/interfaces";
import {UserRole} from "./types/enums";
import {AuthContext, AuthContextType} from "./Library/AuthContext";
import {SidebarContext, SidebarContextType} from "./Library/SidebarContext";
import ProfileView from "./Modules/Profile/ProfileView";
import SurveyManagementView from "./Modules/Surveys/SurveyManagementView";
import SurveyEditorView from "./Modules/Surveys/SurveyEditorView";
import ReportManagementView from "./Modules/Reports/ReportManagementView";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import BankManagementView from "./Modules/Bank/BankManagementView";
import SurveyAnswerView from "./Modules/SurveyAnswers/SurveyAnswerView";
import ArchivedSurveysListView from "./Modules/Surveys/ArchivedSurveysListView";
import NotificationManagerView from "./Modules/NotificationManager/NotificationManagerView";
import ImportBulkView from "./Modules/Surveys/ExcelImportSurveys";

let authenticationLoading = false;

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode: prefersDarkMode ? 'dark' : 'light',
                primary: {
                    main: orange[900],
                },
                secondary: {
                    main: "#22222", //blue[900],
                    dark: "#FFFFFF"
                },
            },
          }),
      [prefersDarkMode],
    );

    const [userToken, setUserToken] = useLocalStorage("userToken", null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];

    const [user, setUser] = React.useState(null) as [IUser | null, React.Dispatch<React.SetStateAction<IUser | null>>];
    const [loadedUserToken, setLoadedUserToken] = React.useState<string | null>(null);
    const [adminUserToken, setAdminUserToken] = useLocalStorage("adminUserToken", null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];

    const [tokenExpired, setTokenExpired] = React.useState<boolean>(false);

    const fetchUser = React.useCallback(async () => {
        if (authenticationLoading){
            return;
        }

        if (loadedUserToken === userToken) {
            return;
        }
        setLoadedUserToken(userToken);
        authenticationLoading = true;
        try {
            if (userToken === null || userToken === undefined) {
                setUser(null);
                return;
            }
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_SECURE ? "s" :"" }://${baseURL}/api/auth/me`, {
                method: "POST",
                headers: {
                    "authorization": `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });

            if (results.status !== 200) {
                console.log("clearing token")
                setUserToken(null);
            }
            else {
                let data = await results.json();
                setUser(data);
            }
        } catch (e) {
            console.log(e);
            if (user !== null) {
                setUser(null);
            }
            alert("Your session has expired. Please log in again.")
        }
        finally {
            authenticationLoading = false;
        }
    }, [userToken, user, setUser, setUserToken, loadedUserToken]);


    React.useEffect(() => {
        // Define the function you want to run every 10 seconds
        const myFunction = async () => {
            console.log('This function runs every 100 seconds.');
            // You can put your code here

            try {
                if (userToken === null || userToken === undefined) {
                    setTokenExpired(true)
                    return;
                }

                console.log("checking token: " + userToken)

                const baseURL = process.env.REACT_APP_SERVER_URL || "localhost:3000";
                let results = await fetch(`http${ process.env.REACT_APP_SERVER_SECURE ? "s" :"" }://${baseURL}/api/auth/me`, {
                    method: "POST",
                    headers: {
                        "authorization": `Bearer ${userToken}`,
                        "Content-Type": "application/json"
                    }
                });

                if (results.status !== 200) {
                    console.log("clearing token")
                    setTokenExpired(true)
                    // setUserToken(null);
                }
                else {
                    // let data = await results.json();
                    setTokenExpired(false)
                    // setUser(data);
                }
            } catch (e) {
                setTokenExpired(true)
                // console.log(e);
                // if (user !== null) {
                //     setUser(null);
                // }
                // alert("Your session has expired. Please log in again.")
            }
            finally {
                // authenticationLoading = false;
            }

        };

        // Run the function immediately when the component mounts
        // myFunction().then(r => {});

        // Schedule the function to run every 10 seconds
        const intervalId = setInterval(() => {
            myFunction().then(r => {})
        }, 10 * 1000); // 100000  milliseconds = 100 seconds

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, [userToken]);



    React.useEffect(() => {
        fetchUser().then(r => {});
    }, [userToken, setUser, fetchUser]);


    const [showSidebar, setShowSidebar] = useLocalStorage("showSidebar", true);

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        // isAuthenticated: !!sessionContext.isAuthenticated,
        getAuthenticationStatus: () => {
            if (userToken === null || user === null) {
                return AuthenticationStatus.UNAUTHENTICATED;
            }
            return AuthenticationStatus.AUTHORIZED;
        },
        authenticationPath: '/'
    };

    const userIncludesRole = (role: UserRole) => {
        if (userToken === null || user === null) {
            return AuthenticationStatus.UNAUTHENTICATED;
        }
        if (user.role === null || user.role === undefined) {
            return AuthenticationStatus.UNAUTHORIZED;
        }
        if (!user.role.includes(role)) {
            return AuthenticationStatus.UNAUTHORIZED;
        }
        return AuthenticationStatus.AUTHORIZED;
    }


    // function onTokenExpired() {
    //     setUserToken(null);
    //     setAdminUserToken(null)
    //
    // }

    const authContextValue: AuthContextType = {
        userToken,
        setUserToken,
        user,
        setUser,
        adminUserToken,
        setAdminUserToken,
        // onTokenExpired
        tokenExpired,
        setTokenExpired
    } ;

    const sidebarContextValue: SidebarContextType = {
        showSidebar,
        setShowSidebar
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
            {/*<Elements stripe={stripePromise}>*/}
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthContext.Provider value={authContextValue}>
                    <SidebarContext.Provider value={sidebarContextValue}>
                        <div>
                            {/*<p>{process.env.REACT_APP_SERVER_URL || "Unknown URL"}</p>*/}
                            {/*<p>{process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}</p>*/}
                            {/*<p>{process.env.STRIPE_PUBLISHABLE_KEY}</p>*/}
                            <BrowserRouter>
                                <Routes>
                                    <Route index element={user === null || userToken === null ? <UnauthenticatedMainView /> : <SurveyManagementView/> }/>
                                    {/*<Route path="login" element={<UnauthenticatedMainView {...unauthenticatedMainViewProps} />} />*/}
                                    {/*<Route path="signup/:code" element={<RegistrationView />} />*/}
                                    {/*<Route path="createAccount" element={<AccountCreationView />} />*/}

                                    <Route path="dashboard">
                                        {/*<Route index element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyManagementView />} />} />*/}
                                        <Route path="users" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => userIncludesRole(UserRole.ADMIN)} outlet={<UserManagementView />} />} />
                                        <Route path="profile" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ProfileView />} />} />
                                        <Route path="bank" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<BankManagementView />} />} />
                                        {/*<Route path="surveys" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyManagementView />} />} />*/}
                                        <Route path="surveys/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyEditorView />} />} />
                                        <Route path="import" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ImportBulkView />} />} />
                                        <Route path="archived_surveys" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ArchivedSurveysListView />} />} />
                                        <Route path="surveyAnswer/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyAnswerView />} />} />
                                        <Route path="reports" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ReportManagementView />} />} />
                                        <Route path="notifications" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<NotificationManagerView />} />} />
                                        {/*<Route path="reports/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyEditorView />} />} />*/}

                                        {/*<Route path="canvas" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => userIncludesRole(UserRole.ADMIN)} outlet={<CardDesignerView />} />} />*/}
                                        {/*<Route index element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ContactCardView />} />} />*/}
                                        {/*<Route path="users/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CustomizedUserEditView {...authenticatedMainViewProps} />} />} />*/}
                                        {/*<Route path="form_customizer/:controller/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ClientFormFieldCustomizer {...authenticatedMainViewProps} />} />} />*/}
                                        {/*<Route path="clients" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ClientManagementView {...authenticatedMainViewProps} />} />} />*/}
                                        {/*<Route path="clients/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ClientManagementView {...authenticatedMainViewProps} />} />} />*/}
                                    </Route>

                                    <Route path="*" element={<p>There's nothing here: 404!</p>} />
                                </Routes>
                            </BrowserRouter>

                        </div>
                    </SidebarContext.Provider>
                </AuthContext.Provider>
              </ThemeProvider>
            {/*</Elements>*/}
            </LocalizationProvider>
        </DndProvider>
    );
}

export default App;
