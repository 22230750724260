import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {BankDataController_QuickQuestionGroup} from "./BankDataController_QuickQuestionGroup";
import {IQuickQuestionGroup} from "../../types/interfaces";
import {GridCellParams, GridValidRowModel} from "@mui/x-data-grid";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGridPro} from "@mui/x-data-grid-pro";

export function QuestionGroupsListView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickQuestionGroup(userToken).getAll();
            setQuickQuestionGroupsList(all);
        }
        loadData().then();
    }, [userToken]);

    const [quickQuestionGroupsList, setQuickQuestionGroupsList] = React.useState<IQuickQuestionGroup[]>([]);


    const columns = [
        { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'name', headerName: 'Name', width: 250, editable: false },
        { field: 'questions.length', headerName: 'Question Count', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
                let quickQuestion: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
                return quickQuestion.content.length;
            }},
        // { field: 'content.key', headerName: 'Key', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
        //         let quickQuestion: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
        //         return quickQuestion.content.key;
        //     }},
        // { field: 'content.questionText', headerName: 'Question Text', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
        //         let quickQuestion: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
        //         return quickQuestion.content.questionText;
        //     }},
        // { field: 'content.type', headerName: 'Question Type', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
        //         let quickQuestion: IQuickQuestion = params.row as IQuickQuestionGroup;
        //         return quickQuestion.content.type.type;
        //     }},
        { field: 'delete_btn', headerName: '', width: 75, renderCell: (params: GridCellParams) => {
                return (<IconButton color={"error"} onClick={() => {
                    if (window.confirm(`Are you sure you want to delete ${params.row.name}?`)) {
                        let item = params.row as IQuickQuestionGroup & { _id: string };
                        if (userToken === null || userToken === undefined) {
                            alert("You must be logged in to export.");
                            return;
                        }
                        new BankDataController_QuickQuestionGroup(userToken).delete(item._id).then(async (response) => {

                            let all = await new BankDataController_QuickQuestionGroup(userToken).getAll();
                            setQuickQuestionGroupsList(all);
                            // alert("Deleted successfully.");
                            // setQuickObjectsList(quickObjectsList.filter((item: IQuickObject) => item._id !== params.row._id));
                        });
                    }
                }}><DeleteIcon /></IconButton>)
            }},
    ]


    return (

        <DataGridPro
            // make sure to set height to 100% so that the grid fills the whole container
            style={{ height: 'calc( 100% - 5rem )' }}
            rows={quickQuestionGroupsList}
            columns={columns}
            getRowId={(row: GridValidRowModel) => row._id}
            pageSizeOptions={[200]}
            disableRowSelectionOnClick
        />

    );
}
