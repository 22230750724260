import {ListItemButton, ListItemIcon, Paper, Stack, Typography} from "@mui/material";
import List from "@mui/material/List";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle, faUsers, faHome} from "@fortawesome/free-solid-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import React from "react";
import ScrollPane from "./ScrollPane";
import {UserRole} from "../types/enums";
import {AuthContext} from "./AuthContext";
import {useNavigate} from "react-router-dom";

function Sidebar(props: {saveRequired: boolean | undefined}) {
    const {
        user,
        setUserToken,
        adminUserToken,
        setAdminUserToken
    } = React.useContext(AuthContext)!;

    const {
        saveRequired
    } = props;

    let navigate = useNavigate();

    return (
        <div>
            <Paper elevation={1} style={{height: 'calc( var(--app-height) - 0rem - 48px )', padding: '1rem', borderRadius: 0, width: '300px'}}>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={0}
                    style={{height: '100%'}}
                >
                    <ScrollPane>
                        <List dense={false}>
                            <ListItemButton
                                disabled={saveRequired === true}
                                onClick={() => {
                                    // navigate("/dashboard/");
                                    navigate("/");
                                }}
                                // component="a" href="/dashboard/"
                            >
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHome} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Surveys"
                                />
                            </ListItemButton>

                            {user !== undefined && user !== null && user.role !== undefined && (user.role.includes(UserRole.ADMIN) || user.role.includes(UserRole.MANAGER))&& (<>
                                <ListItemButton
                                    disabled={saveRequired === true}
                                    onClick={() => {
                                        navigate("/dashboard/archived_surveys");
                                    }}
                                    // component="a" href="/dashboard/reports"
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faHome} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Archived Surveys"
                                    />
                                </ListItemButton>
                            </>)}


                            <ListItemButton
                                disabled={saveRequired === true}
                                onClick={() => {
                                    navigate("/dashboard/reports");
                                }}
                                // component="a" href="/dashboard/reports"
                            >
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faHome} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Surveys Reports"
                                />
                            </ListItemButton>


                            {user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && (<>
                                <ListItemButton
                                    disabled={saveRequired === true}
                                    onClick={() => {
                                        navigate("/dashboard/bank");
                                    }}
                                    // component="a" href="/dashboard/reports"
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faHome} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Bank"
                                    />
                                </ListItemButton>
                            </>)}

                            {user !== undefined && user !== null && (<>
                                <ListItemButton
                                    disabled={saveRequired === true}
                                    onClick={() => {
                                        navigate("/dashboard/profile");
                                    }}
                                    // component="a" href="/dashboard/profile"
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </ListItemIcon>
                                    <ListItemText
                                        // primary="Profile"
                                        primary="Change Password"
                                    />
                                </ListItemButton>
                            </>)}

                            {user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && (<>
                                <hr style={{ opacity: 0.1 }} />
                            </>)}

                            {user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && user.email_address === "justin.allen@pivital.com" && (<>
                                <ListItemButton
                                    disabled={saveRequired === true}
                                    onClick={() => {
                                        navigate("/dashboard/notifications");
                                    }}
                                    // component="a" href="/dashboard/reports"
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faHome} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Notification"
                                    />
                                </ListItemButton>
                            </>)}

                            {user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && (<>
                                <ListItemButton
                                    disabled={saveRequired === true}
                                    onClick={() => {
                                        navigate("/dashboard/import");
                                    }}
                                    // component="a" href="/dashboard/reports"
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faHome} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Import"
                                    />
                                </ListItemButton>
                            </>)}


                            {user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && (<>
                                <ListItemButton
                                    disabled={saveRequired === true}
                                    onClick={() => {
                                        navigate("/dashboard/users");
                                    }}
                                    // component="a" href="/dashboard/users"
                                >
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faUsers} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Users"
                                    />
                                </ListItemButton>
                            </>)}

                            {/*{user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && (<>*/}
                            {/*    <ListItemButton component="a" href="/dashboard/betacodes">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <FontAwesomeIcon icon={faPause} />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText*/}
                            {/*            primary="Beta Codes"*/}
                            {/*        />*/}
                            {/*    </ListItemButton>*/}
                            {/*</>)}*/}

                            {/*{user !== undefined && user !== null && user.role !== undefined && user.role.includes(UserRole.ADMIN) && (<>*/}
                            {/*    <ListItemButton component="a" href="/dashboard/canvas">*/}
                            {/*        <ListItemIcon>*/}
                            {/*            <FontAwesomeIcon icon={faPause} />*/}
                            {/*        </ListItemIcon>*/}
                            {/*        <ListItemText*/}
                            {/*            primary="Canvas"*/}
                            {/*        />*/}
                            {/*    </ListItemButton>*/}
                            {/*</>)}*/}

                        </List>
                    </ScrollPane>

                    <List dense={false}>
                        <hr style={{ opacity: 0.1 }} />

                        {adminUserToken !== null && adminUserToken !== undefined && (<>
                            <ListItemButton
                                disabled={saveRequired === true}
                                onClick={() => {
                                setUserToken(adminUserToken);
                                setAdminUserToken(null);
                            }}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Back to Admin"
                                />
                            </ListItemButton>
                        </>)}

                        <ListItemButton onClick={() => {
                            setUserToken(null);
                            // this didn't seem to work.
                        }}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Logout"
                            />
                        </ListItemButton>

                        <ListItem style={{ textAlign: "center", display: "block", opacity: 0.5 }}>
                            {(user !== undefined && user !== null && user.role === UserRole.ADMIN) && (<>
                                <Typography variant={'caption'} style={{ textAlign: "center", display: "block", opacity: 0.5 }}>Role: { user.role }</Typography>
                            </>)}
                            <Typography variant={'caption'} style={{ textAlign: "center", display: "block", opacity: 0.5 }}>Version 0.0.1</Typography>
                            <Typography variant={'caption'} style={{ textAlign: "center", display: "block", opacity: 0.5 }}>COPYRIGHT &copy; { new Date().getFullYear() }</Typography>
                        </ListItem>
                    </List>
                </Stack>
            </Paper>
        </div>
        // </ThemeProvider>
    );
}

export default Sidebar;