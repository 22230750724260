import {CustomFormField, ISurvey, IUser} from "../../types/interfaces";
import React, {ChangeEvent} from "react";
import * as XLSX from "xlsx";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {DataGrid, GridColDef, GridRowModel, GridValidRowModel} from "@mui/x-data-grid";
import {Box, Button, Modal, Stack} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {EditObject} from "../../Shared/Components/EditObject";
import {AuthContext} from "../../Library/AuthContext";
import {ChangeUserController, UserDataController} from "../Users/UserDataController";
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {AddNewUser} from "../Users/UserManagementView";
import {SurveyDataController} from "./SurveyDataController";


function ImportBulkView() {
    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [inputData, setInputData] = React.useState<object[]>([]);

    const [userList, setUserList] = React.useState<IUser[]>([]);
    // TODO: bring over the thing that loads this list.
    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken]);


    async function ImportSelectedSurveys() {
        // TODO: async loop over each survey and import it.
        if (userToken === null || userToken === undefined) {
            return;
        }

        interface MyObject {
            [key: string]: any;  // Now the object can be indexed with any string, and will return any type.
        }


        let controller = new SurveyDataController(userToken);
        let asyncFunctions = inputData.map(async (row) => {

            let object= row as MyObject;



            // return async () => {
                console.log("importing row")
                // get the source id from the row
                let sourceMapping = mapping["sourceSurveyId"];
                let sourceId = object[sourceMapping];

                let surveyObject: ISurvey = {
                    caseNumber: object[mapping["caseNumber"]],
                    surveyName: object[mapping["surveyName"]],
                    assigned_user_id: object[mapping["assigned_user_id"]],
                    coordinator_user_id: object[mapping["coordinator_user_id"]],
                    companyName: object[mapping["companyName"]],
                    projectName: object[mapping["projectName"]],
                    // sourceSurveyId: object[mapping["sourceSurveyId"]],
                    surveyOwner: object[mapping["surveyOwner"]],
                    locations: [],
                    address: object[mapping["address"]],
                    content: []
                }

                let survey = await controller.import(surveyObject, sourceId);

                console.log(survey)
                // get the rest of the mapped data into the correct object for the ISurvey

            // }
        })

        await Promise.all(asyncFunctions)
    }


    function SelectAndReadInputExcel() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (e: any) => {
            const file = e.target.files?.[0];
            if (!file) return;

            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target?.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                console.log(ws)
                const data = XLSX.utils.sheet_to_json<Record<string, any>>(ws);
                if (data === undefined || data === null) {
                    return;
                }

                // loop over each row and give it a unique id
                data.forEach((row, index) => {
                    row['id'] = index;
                })
                console.log(data); // Process your data here
                setInputData(data)
            };
            reader.readAsBinaryString(file);
        };
        input.click();
    }

    // const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    //     const file = e.target.files?.[0];
    //     if (!file) return;
    //
    //     const reader = new FileReader();
    //     reader.onload = (evt) => {
    //         const bstr = evt.target?.result;
    //         const wb = XLSX.read(bstr, { type: 'binary' });
    //         const wsname = wb.SheetNames[0];
    //         const ws = wb.Sheets[wsname];
    //         console.log(ws)
    //         const data = XLSX.utils.sheet_to_json<Record<string, any>>(ws);
    //         if (data === undefined || data === null) {
    //             return;
    //         }
    //
    //
    //         console.log(data); // Process your data here
    //         setInputData(data)
    //     };
    //     reader.readAsBinaryString(file);
    // };

    const [mapping, setMapping] = React.useState<{[key: string]: string}>({
        "caseNumber": "caseNumber",
        "surveyName": "surveyName",
        "assigned_user_id": "assigned_user_id",
        "coordinator_user_id": "coordinator_user_id",
        "companyName": "companyName",
        "projectName": "projectName",
        "sourceSurveyId": "sourceSurveyId",
        "surveyOwner": "surveyOwner",
        "address": "address",

    });


    const formFieldsMemo = React.useMemo(() => {
        let formFields: CustomFormField[] = []

        if (inputData.length === 0) {
            return formFields;
        }

        let options = Object.keys(inputData[0]).map((key) => {
            return { label: key, value: key };
        });

        suggestedColumns.forEach((column) => {
            let listOfOptionsAvailableOrSelectedByCurrentColumn = options.filter((option) => {
                let mappingValues = Object.values(mapping);
                return (!mappingValues.includes(option.value) || mapping[column.source] === option.value)
            })

            formFields.push({
                key: column.source,
                label: column.label,
                kind: CustomFormFieldTypes.SELECT,
                size: CustomFormFieldSizes.ONE,
                options: listOfOptionsAvailableOrSelectedByCurrentColumn
            });
        })


        return formFields;


    }, [inputData, mapping]);

    const dataGridColumns = React.useMemo(() => {

        // Show all mapped columns

        // show all unmapped columns



        let columns: GridColDef[] = [];

        // columns.push({
        //     field: "id",
        //     headerName: "ID",
        //     width: 45,
        //     editable: false,
        // })


        if (inputData.length === 0) {
            return columns;
        }

        // let expectedColumns = ["caseNumber", "surveyName", "assigned_user_id", "coordinator_user_id", "companyName", "projectName", "sourceSurveyId"];
        suggestedColumns.forEach((column) => {



            let key = mapping[column.source];

            let specialKeys = ["assigned_user_id", "coordinator_user_id"];
            let mappedSpecialKeys = specialKeys.map((k) => mapping[k]);


            columns.push({
                field: key,
                headerName: column.label,
                width: 300,
                editable: false,
                valueGetter: mappedSpecialKeys.includes(key) ? (params) => {
                    let user = userList.find((u) => u._id === params.row[key]);
                    return user?.name || '';
                }: undefined,
            })
        });


        let keys = Object.keys(inputData[0]);
        keys.forEach((key) => {
            let listOfUsedKeys = Object.values(mapping);

            if (!listOfUsedKeys.includes(key) && key !== "id") {
                columns.push({
                    field: key,
                    headerName: key,
                    width: 300,
                    editable: false
                })
            }
        })


        return columns;
    }, [mapping, inputData]);

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };


    const [importedSurveys, setImportedSurveys] = React.useState<number[]>([]);
    const isRowSelectable = (params: any) => {
        return !importedSurveys.includes(params.row.id); // Example condition
    };

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Import Bulk Surveys"}
                customButtons={[
                    {
                        label: "Upload Excel",
                        action: () => {
                            SelectAndReadInputExcel();
                        },
                        icon: <CloudUploadIcon />
                    },
                    {
                        label: "Import Surveys",
                        action: () => {
                            // SelectAndReadInputExcel();
                            // console.log(selectionModel);
                            // let surveysToImport = selectionModel.map((row: GridRowModel) => {
                            //     console.error(row)
                            //     alert(JSON.stringify(row))
                                // return row;
                                // return inputData[row]; //.id;
                                // return JSON.parse(row.caseNumber);
                            // });

                            ImportSelectedSurveys().then(() =>{
                                setSelectionModel([])
                            });

                            // /// TODO: this is not the right way to do this
                            // setImportedSurveys([
                            //     0
                            // ])
                            // setSelectionModel([])
                            // setImportedSurveys([...surveysToImport, ...importedSurveys]);
                        },
                        icon: <CloudUploadIcon />
                    }
                ]}
            >

                <Stack style={{height: '100%', width: '100%'}}>
                    {/*<Button*/}
                    {/*    variant="contained"*/}
                    {/*    component="label"*/}
                    {/*    startIcon={<CloudUploadIcon />}  // Optional*/}
                    {/*>*/}
                    {/*    Upload Excel*/}
                    {/*    <input*/}
                    {/*        type="file"*/}
                    {/*        hidden*/}
                    {/*        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"*/}
                    {/*        onChange={handleFileUpload}*/}
                    {/*    />*/}
                    {/*</Button>*/}

                    <br />
                    <br />


                    {/*<pre>{JSON.stringify(dataGridColumns, undefined, 4)}</pre>*/}

                    <EditObject item={mapping} setItem={setMapping} form={formFieldsMemo} columns={4} />

                    <br />
                    <DataGrid
                        // make sure to set height to 100% so that the grid fills the whole container
                        style={{height: '100%', width: '100%'}}
                        rows={inputData}
                        columns={dataGridColumns}
                        // getRowId={(row: GridValidRowModel) => JSON.stringify(row)}
                        pageSizeOptions={[200]}
                        disableRowSelectionOnClick
                        checkboxSelection={true}
                        onRowSelectionModelChange={handleSelection}
                        rowSelectionModel={selectionModel}
                        isRowSelectable={isRowSelectable}
                    />


                    <pre>{JSON.stringify(selectionModel)}</pre>
                    <pre>{JSON.stringify(importedSurveys)}</pre>

                </Stack>



                {/*<ExcelImportSurveys*/}
                {/*    onCreate={async (survey: ISurvey) => {*/}
                {/*        return false;*/}
                {/*    }}*/}
                {/*/>*/}

            </AuthenticatedLayout>
        </div>
    );
}

export default ImportBulkView;

const suggestedColumns = [
    { label: "Case Number", source: "caseNumber" },
    { label: "Survey Name", source: "surveyName" },
    { label: "Assigned User ID", source: "assigned_user_id" },
    { label: "Coordinator User ID", source: "coordinator_user_id" },
    { label: "Company Name", source: "companyName" },
    { label: "Project Name", source: "projectName" },
    { label: "Source Survey ID", source: "sourceSurveyId" },
    { label: "Survey Owner", source: "surveyOwner" },
    { label: "Address", source: "address" },
]
// export function ExcelImportSurveys(props: { onCreate: ((survey: ISurvey) => Promise<boolean>) })  {
//
//     const [inputData, setInputData] = React.useState<object[]>([]);
//
//     const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
//         const file = e.target.files?.[0];
//         if (!file) return;
//
//         const reader = new FileReader();
//         reader.onload = (evt) => {
//             const bstr = evt.target?.result;
//             const wb = XLSX.read(bstr, { type: 'binary' });
//             const wsname = wb.SheetNames[0];
//             const ws = wb.Sheets[wsname];
//             console.log(ws)
//             const data = XLSX.utils.sheet_to_json<Record<string, any>>(ws);
//             if (data === undefined || data === null) {
//                 return;
//             }
//
//
//             console.log(data); // Process your data here
//             setInputData(data)
//         };
//         reader.readAsBinaryString(file);
//     };
//
//     const [mapping, setMapping] = React.useState<{[key: string]: string}>({
//         "caseNumber": "caseNumber",
//         "surveyName": "surveyName",
//         "assigned_user_id": "assigned_user_id",
//         "coordinator_user_id": "coordinator_user_id",
//         "companyName": "companyName",
//         "projectName": "projectName",
//         "sourceSurveyId": "sourceSurveyId",
//     });
//
//
//     const formFieldsMemo = React.useMemo(() => {
//         let formFields: CustomFormField[] = []
//
//         if (inputData.length === 0) {
//             return formFields;
//         }
//
//         let options = Object.keys(inputData[0]).map((key) => {
//             return { label: key, value: key };
//         });
//
//         suggestedColumns.forEach((column) => {
//             let listOfOptionsAvailableOrSelectedByCurrentColumn = options.filter((option) => {
//                 let mappingValues = Object.values(mapping);
//                 return (!mappingValues.includes(option.value) || mapping[column.source] === option.value)
//             })
//
//             formFields.push({
//                 key: column.source,
//                 label: column.label,
//                 kind: CustomFormFieldTypes.SELECT,
//                 size: CustomFormFieldSizes.ONE,
//                 options: listOfOptionsAvailableOrSelectedByCurrentColumn
//             });
//         })
//
//
//         return formFields;
//
//
//     }, [inputData, mapping]);
//
//     const dataGridColumns = React.useMemo(() => {
//
//         // Show all mapped columns
//
//         // show all unmapped columns
//
//
//
//         let columns: GridColDef[] = [];
//
//         if (inputData.length === 0) {
//             return columns;
//         }
//
//         // let expectedColumns = ["caseNumber", "surveyName", "assigned_user_id", "coordinator_user_id", "companyName", "projectName", "sourceSurveyId"];
//         suggestedColumns.forEach((column) => {
//             let key = mapping[column.source];
//             columns.push({
//                 field: key,
//                 headerName: column.label,
//                 width: 300,
//                 editable: false,
//             })
//         });
//
//
//         let keys = Object.keys(inputData[0]);
//         keys.forEach((key) => {
//
//             let listOfUsedKeys = Object.values(mapping);
//
//             if (!listOfUsedKeys.includes(key)) {
//                 columns.push({
//                     field: key,
//                     headerName: key,
//                     width: 300,
//                     editable: false,
//                 })
//             }
//         })
//
//
//         return columns;
//     }, [mapping, inputData]);
//
//
//     return (
//         <>
//
//             <Stack style={{height: '100%', width: '100%'}}>
//                 <Button
//                     variant="contained"
//                     component="label"
//                     startIcon={<CloudUploadIcon />}  // Optional
//                 >
//                     Upload Excel
//                     <input
//                         type="file"
//                         hidden
//                         accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
//                         onChange={handleFileUpload}
//                     />
//                 </Button>
//
//                 <br />
//                 <br />
//
//
//                 {/*<pre>{JSON.stringify(dataGridColumns, undefined, 4)}</pre>*/}
//
//                 <EditObject item={mapping} setItem={setMapping} form={formFieldsMemo} columns={4} />
//
//                 <br />
//                 <DataGrid
//                     // make sure to set height to 100% so that the grid fills the whole container
//                     style={{height: '100%', width: '100%'}}
//                     rows={inputData}
//                     columns={dataGridColumns}
//                     getRowId={(row: GridValidRowModel) => JSON.stringify(row)}
//                     pageSizeOptions={[200]}
//                     disableRowSelectionOnClick/>
//             </Stack>
//
//         </>
//
//
//     );
// }
//
//
