// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";

import {Box, Tab, Tabs} from "@mui/material";
// https://mui.com/material-ui/icons/#font-awesome
import {QuestionsListView} from "./QuestionsListView";
import {QuestionGroupsListView} from "./QuestionGroupsListView";
import {ObjectListView} from "./ObjectListView";

function BankManagementView() {

    const [tab, setTab] = React.useState(1);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabContent() {
        switch (tab) {
            case 0:
                return <ObjectListView />;
            case 1:
                return <QuestionsListView />;
            case 2:
                return <QuestionGroupsListView />;
            default:
                return <div>Unknown Tab</div>;
        }
    }


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Bank"} >

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={(e, nv) => {
                        setTab(nv);
                    }} aria-label="basic tabs example">
                        <Tab label="Objects" {...a11yProps(0)}  />
                        <Tab label="Questions" {...a11yProps(1)} />
                        <Tab label="Question Groups" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <br />

                <TabContent />

            </AuthenticatedLayout>
        </div>
    );
}










// function EditButtonModalContainer(props: { params: GridCellParams, item: SurveyObject, setItem: (item: SurveyObject) => void, index: number }) {
//     const [showModal, setShowModal] = React.useState<boolean>(false);
//     return (<>
//         {showModal && (
//             <EditQuestion question={props.params.row as SurveyObjectQuestion} surveyObject={props.item} open={showModal} onClose={() =>{ setShowModal(false) }} saveQuestion={(question: SurveyObjectQuestion) => {
//                 let questions: SurveyObjectQuestion[] = [...props.item.questions];
//                 questions[props.index] = question;
//                 let item = {...props.item, questions};
//                 props.setItem(item);
//                 return true;
//             }} />
//         )}
//         <IconButton color={"warning"} onClick={() => {
//             setShowModal(true)
//         }}><EditIcon /></IconButton>
//     </>);
// }

export default BankManagementView;