import {
    DataController, IQuickQuestion, IQuickQuestionGroup
} from "../../types/interfaces";

export class BankDataController_QuickQuestionGroup implements DataController<IQuickQuestionGroup> {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getAll(): Promise<IQuickQuestionGroup[]> {
        let results = await fetch(`${this.baseURL}/api/quickQuestionGroups`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

    public async getOne(id: String): Promise<IQuickQuestionGroup> {
        let results = await fetch(`${this.baseURL}/api/quickQuestionGroups/${id}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        // console.log(data);
        return await data; // this doesn't seem to type check.
    }

    public async create(item: IQuickQuestionGroup): Promise<IQuickQuestionGroup> {
        let results = await fetch(`${this.baseURL}/api/quickQuestionGroups/`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data.item; // this doesn't seem to type check.
    }

    public async update(id: string, item: IQuickQuestionGroup): Promise<IQuickQuestionGroup> {
        let results = await fetch(`${this.baseURL}/api/quickQuestionGroups/${id}`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });

        // handle when results is 304
        if (results.status === 304) {
            console.log("304");
            return item;
        }

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

    public async delete(id: string): Promise<IQuickQuestionGroup> {
        let results = await fetch(`${this.baseURL}/api/quickQuestionGroups/${id}`, {
            method: "DELETE",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            }
            // body: JSON.stringify(item)
        });

        console.log(results)
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check. change to .item when api updated
    }

}
