

/*

TODO: list out each component and test them individually.
need view for toggle answer (Done?)
need view for text answer (Done?)
need view for select answer (Done?)
need view for photo answer (Done?)
need view for geo coordinate answer
need view for date answer
need view for multi select answer
need to build in the save function.
need view that combines all of them.
need option to delete photos on web.

need to still support disabled views for if you are viewing a readonly survey.


 */

import {
    Answer,
    ISurvey,
    ISurveyAnswer,
    SurveyObject,
    SurveyObjectQuestion,
} from "../../../types/interfaces";
import React from "react";
import {ToggleAnswerView} from "./AnswerViews/ToggleAnswerView";
import {SelectAnswerView} from "./AnswerViews/SelectAnswerView";
import {TextAnswerView} from "./AnswerViews/TextAnswerView";
import {GeoCoordinateAnswerView} from "./AnswerViews/GeoCoordinateAnswerView";
import {PhotoAnswerView} from "./AnswerViews/PhotoAnswerView";
import {MultiSelectAnswerView} from "./AnswerViews/MultiSelectAnswerView";

export interface AnswerViewProps {
    survey: ISurvey & { _id: string },
    surveyObject: SurveyObject,
    question: SurveyObjectQuestion,
    surveyAnswer: ISurveyAnswer,
    setSurveyAnswer: React.Dispatch<React.SetStateAction<ISurveyAnswer | undefined>>,
    answer: Answer
}

export function SurveyObjectEdit(props: { row: SurveyObject, surveyAnswer: ISurveyAnswer, setSurveyAnswer: React.Dispatch<React.SetStateAction<ISurveyAnswer | undefined>> }) {
    const { row, surveyAnswer, setSurveyAnswer } = props;

    function getElement(question: SurveyObjectQuestion, answer: Answer) {

        switch (question.type.type) {
            case "toggle":
                return (<>
                    {/*<pre>{JSON.stringify(answer)}</pre>*/}
                    <ToggleAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} survey={surveyAnswer.survey_id} setSurveyAnswer={setSurveyAnswer}  answer={answer} question={question} />
                </>);
            case "select":
                return (<>
                    {/*<pre>{JSON.stringify(answer)}</pre>*/}
                    <SelectAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} survey={surveyAnswer.survey_id} answer={answer} question={question} />
                </>);
            case "text":
                return (<>
                    {/*<pre>{JSON.stringify(answer)}</pre>*/}
                    <TextAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} survey={surveyAnswer.survey_id} answer={answer} question={question} />
                </>);
            case "location":
                return (<>
                    {/*<pre>{JSON.stringify(answer)}</pre>*/}
                    <GeoCoordinateAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} survey={surveyAnswer.survey_id} answer={answer} question={question} />
                </>);
            case "photo":
                return (<>
                    <PhotoAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} survey={surveyAnswer.survey_id} answer={answer} question={question} />
                </>);
            case "geoCoordinate":
                return (<>
                    <GeoCoordinateAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} survey={surveyAnswer.survey_id} answer={answer} question={question} />
                </>);
            case "multiSelect":
                return (<>
                    <MultiSelectAnswerView surveyObject={row as SurveyObject} surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} survey={surveyAnswer.survey_id} answer={answer} question={question} />
                </>);


            default:
                return (<>{question.type.type}</>);
        }
    }

    const itemMemo = React.useMemo(() => {

        if (surveyAnswer === undefined) { return {}; }
        if (surveyAnswer.answers === undefined) { return {}; }
        if (surveyAnswer.answers[(row as SurveyObject).key] === undefined) { return {}; }

        // have to deal with the "select"->"data" structure here.
        return surveyAnswer.answers[(row as SurveyObject).key].values || {};
    } , [surveyAnswer, row] );

    return (<>
        {itemMemo !== undefined && (<>
            { row.questions.map((question: SurveyObjectQuestion, index: number) => {
                return (
                    <div key={index} style={{ padding: '1rem' }}>
                        {getElement(question, itemMemo[question.key])}
                    </div>
                );
            })}
        </>)}

    </>);
}



export class S3GetController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async getURL(bucket: string, key: string): Promise<string> {
        let results = await fetch(`${this.baseURL}/api/s3/${bucket}/${encodeURIComponent(key)}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        console.log("data", data)
        return await data.url; // this doesn't seem to type check.
    }

    public async createURL(surveyID: string, fileType: string): Promise<{
        url: string,
        bucket: string,
        key: string
    }> {

        // alert("surveyID: " + surveyID + " fileType: " + fileType);

        let results = await fetch(`${this.baseURL}/api/s3/${surveyID}/${fileType}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            },
            method: "POST",
        });
        let data = await results.json();

        return await data
    }

    public async uploadFile(url: string, file: File): Promise<void> {

        try {
            const response = await fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type, // Set the content type based on the file type
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to upload file. Status: ${response.status}`);
            }

            console.log('File uploaded successfully.');
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }



    }

    public async deleteImage(bucket: string, key: string): Promise<void> {
        let results = await fetch(`${this.baseURL}/api/s3/${bucket}/${key}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            },
            method: "DELETE",
        });
        let data = await results.json();

        return await data
    }

}
