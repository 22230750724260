// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {DataGrid, GridCellParams, GridColDef, GridRowModel, GridValidRowModel} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {CustomFormField, ISurvey, IUser, SurveyObject, SurveyObjectQuestion} from "../../types/interfaces";
import {SurveyDataController} from "./SurveyDataController";
import {AuthContext} from "../../Library/AuthContext";
import {Box, Button, Modal, Tooltip, Typography} from "@mui/material";

import {EditObject} from "../../Shared/Components/EditObject";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {Link as RouterLink} from "react-router-dom";
import {UserDataController} from "../Users/UserDataController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFileImport, faPlus} from "@fortawesome/free-solid-svg-icons";
import {generateCode} from "./SurveyEditorView";
// https://mui.com/material-ui/icons/#font-awesome

// , onCancel: (() => void)
function AddNewSurveyModal(props: { usersList: IUser[], onSave: ((survey: ISurvey) => void), onCancel: (() => void) })  {

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        content: [],
        locations: [],
        // selected technician
    });

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Add New Survey
            </Typography>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                props.onSave(survey);
            }} disabled={ survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );
}

function CloneSurveyModal(props: { usersList: IUser[], onSave: ((survey: ISurvey) => void), onCancel: (() => void), survey: ISurvey })  {

    // const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "coordinator_user_id",
            label: "Coordinator User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "surveyName",
            label: "Survey Name"
        },
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "companyName",
            label: "Company Name"
        },
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "projectName",
            label: "Project Name"
        },
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "surveyOwner",
            label: "Survey Owner"
        },
    ];
    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        assigned_user_id: null,
        content: props.survey.content,
        locations: props.survey.locations,
        coordinator_user_id: props.survey.coordinator_user_id,
        surveyName: props.survey.surveyName,
        companyName: props.survey.companyName,
        projectName: props.survey.projectName,
        surveyOwner: props.survey.surveyOwner,
        // selected technician
    });

    React.useEffect(() => {
        setSurvey({
            // caseNumber: props.survey.caseNumber,
            // assigned_user_id: props.survey.assigned_user_id,
            caseNumber: survey.caseNumber,
            assigned_user_id: (survey.assigned_user_id === undefined ? null : survey.assigned_user_id) ,




            content: props.survey.content,
            locations: props.survey.locations,
            coordinator_user_id: (props.survey.coordinator_user_id === undefined || props.survey.coordinator_user_id === null ? null : props.survey.coordinator_user_id._id),
            surveyName: props.survey.surveyName,
            companyName: props.survey.companyName,
            projectName: props.survey.projectName,
            surveyOwner: props.survey.surveyOwner,
            // selected technician
        });
    }, [props.usersList, props.survey, survey.assigned_user_id, survey.caseNumber]);



    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Clone Survey: {props.survey.caseNumber}
            </Typography>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            {/*<p>Content: {JSON.stringify(survey)}</p>*/}

            {/*<pre>{JSON.stringify(survey, undefined, 4)}</pre>*/}

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                props.onSave(survey);
            }} disabled={ survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );
}







interface CloneSurveyButtonProps {
    userToken: string | null | undefined,
    setList: ((list: ISurvey[]) => void),
    updateList: (() => void),
    // usersList: IUser[],
    survey: ISurvey
}

export function CloneSurveyButton(props: CloneSurveyButtonProps) {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [show, setShow] = React.useState<boolean>(false);

    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    // const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])

    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT));
                // setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken]);


    const updateKeys = React.useCallback((survey: ISurvey) => {
        let updatedSurvey = {...survey};
        let newObjectKeys = new Map<string, string>();

        survey.content.forEach((object) => {
            let newKey = generateCode();
            newObjectKeys.set(object.key, newKey);
        })

        console.log(newObjectKeys)

        updatedSurvey.content = survey.content.map((surveyObject) => {


            let newSurveyObject = {...surveyObject, key: newObjectKeys.get(surveyObject.key) ?? surveyObject.key};

            let objectQuestionKeys = new Map<string, string>();

            surveyObject.questions.forEach((question) => {
                let newKey = generateCode();
                objectQuestionKeys.set(question.key, newKey);
            })

            console.log(objectQuestionKeys)

            // update the questions into new survey Object
            newSurveyObject.questions = newSurveyObject.questions.map((question) => {
                let newQuestion = {...question, key: objectQuestionKeys.get(question.key) ?? question.key};
                newQuestion.conditions = newQuestion.conditions?.map((condition) => {
                    let newCondition = {...condition};
                    newCondition.key = objectQuestionKeys.get(newCondition.key) ?? newCondition.key;
                    return newCondition;
                })
                return newQuestion;
            })


            return newSurveyObject;
        })

        return updatedSurvey;
    }, []);

    return (<>
        <Modal
            open={show}
            onClose={() => { setShow(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {<>
                <CloneSurveyModal
                    usersList={usersList}
                    survey={props.survey}
                    onSave={async (survey: ISurvey) => {
                        if (props.userToken === null || props.userToken === undefined) {
                            return;
                        }

                        console.log(survey)
                        let updatedSurvey = updateKeys(survey) as ISurvey;


                        console.log(updatedSurvey)



                        // updating keys

                        await new SurveyDataController(props.userToken).create(updatedSurvey);

                        // let all = await new SurveyDataController(props.userToken).getAll();
                        // setList(all);
                        props.updateList();
                        setShow(false);
                    }}
                    onCancel={() => { setShow(false) }}
                ></CloneSurveyModal>
            </>}
        </Modal>


        <Tooltip title={"Clone Survey"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                Clone
                {/*<FontAwesomeIcon icon={faCopy} />*/}
            </Button>
        </Tooltip>


    </>);
}

function SurveyManagementView() {

    const {
        user,
        userToken
    } = React.useContext(AuthContext)!;

    const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])
    // const [coordinatorsList, setCoordinatorList] = React.useState<(IUser & {_id: string})[]>([])

    const columns = React.useMemo(() => {
        let columns: GridColDef[] = []


        columns.push({
            field: 'open_btn',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={"Open Survey"} arrow={true}>
                    <Button variant="outlined" color="primary" fullWidth={true} component={RouterLink} to={`/dashboard/surveys/${params.id}`}>
                        Open
                    </Button>
                </Tooltip>


            )
        });

        columns.push({
            field: 'clone_btn',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params: GridCellParams) => (<>

                    <CloneSurveyButton
                        userToken={userToken}
                        setList={setList}
                        survey={params.row as ISurvey}
                        updateList={() => {
                            async function loadData() {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                let all = await new SurveyDataController(userToken).getAll();
                                setList(all);
                            }
                            loadData().then();
                        }}
                        // usersList={usersList}
                    />
                </>
            ),
        });

        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER) {
            columns.push(
                {
                    field: 'soft_delete_btn',
                    headerName: '',
                    sortable: false,
                    width: 100,
                    renderCell: (params: GridCellParams) => (
                        <Tooltip title="Archive Survey" arrow>
                            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {

                                async function restoreRow() {
                                    let row = params.row as ISurvey & { _id: string };
                                    row.isArchived = true;

                                    if (userToken === null || userToken === undefined) {
                                        return;
                                    }
                                    await new SurveyDataController(userToken).update(row._id, row);
                                    let all = await new SurveyDataController(userToken).getAll();
                                    setList(all);
                                }

                                if (window.confirm("Are you sure you want to archive this survey?")){
                                    restoreRow().then();
                                }

                            }}>
                                {/*<FontAwesomeIcon icon={faTrash} />*/}
                                {/*<FontAwesomeIcon icon={faBoxArchive} />*/}
                                Archive
                            </Button>
                        </Tooltip>
                    ),
                }
            )
        }


        columns.push({ field: 'caseNumber', headerName: 'Case Number', width: 300, editable: false });
        columns.push({ field: 'status', headerName: 'Status', width: 300, editable: false });
        columns.push({ field: 'surveyDate', headerName: 'Survey Date', width: 300, editable: false,
            valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;
                    if (survey.surveyDate !== undefined && survey.surveyDate !== null){
                        return new Date(survey.surveyDate).toLocaleString();
                    }
                    else {
                        return ""
                    }
                }
                catch (e: any) {
                    // alert(e.message)
                    return ""
                }
            }
        });
        columns.push({ field: 'assigned_user_id', headerName: 'Assigned User', width: 300, editable: false, valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;
                    if (survey.assigned_user_id !== undefined){
                        let user = survey.assigned_user_id as IUser;
                        return user.name;
                    }
                    else {
                        return "Unassigned"
                    }
                }
                catch (e) {
                    return "Unassigned"
                }

            } });
        columns.push({ field: 'coordinator_user_id', headerName: 'Coordinator', width: 300, editable: false, valueGetter: params => {
                try {
                    let survey = params.row as ISurvey;
                    if (survey.coordinator_user_id !== undefined){
                        let user = survey.coordinator_user_id as IUser;
                        return user.name;
                    }
                    else {
                        return "Unassigned"
                    }
                }
                catch (e) {
                    return "Unassigned"
                }

            } });
        columns.push({ field: 'surveyOwner', headerName: 'Survey Owner', width: 300, editable: false });
        columns.push({ field: 'createdAt', headerName: 'Created At', width: 300, editable: false,
            valueFormatter: params => {
                try {
                    if (params.value === undefined || params.value === null) {
                        return "";
                    }
                    let date = new Date(params.value as string);
                    return date.toLocaleString();
                }
                catch (e) {
                    // console.log(e);
                    return "";
                }
            }
        });
        // columns.push({
        //     field: 'clone_btn',
        //     headerName: '',
        //     sortable: false,
        //     width: 100,
        //     renderCell: (params: GridCellParams) => (<>
        //
        //             <CloneSurveyButton
        //                 userToken={userToken}
        //                 setList={setList}
        //                 survey={params.row as ISurvey}
        //                 updateList={() => {
        //                     async function loadData() {
        //                         if (userToken === null || userToken === undefined) {
        //                             return;
        //                         }
        //                         let all = await new SurveyDataController(userToken).getAll();
        //                         setList(all);
        //                     }
        //                     loadData().then();
        //                 }}
        //                 // usersList={usersList}
        //             />
        //         </>
        //     ),
        // });






        if (user?.role === UserRole.ADMIN || user?.role === UserRole.MANAGER) {
            // columns.push(
            //     {
            //         field: 'soft_delete_btn',
            //         headerName: '',
            //         sortable: false,
            //         width: 100,
            //         renderCell: (params: GridCellParams) => (
            //             <Tooltip title="Archive Survey" arrow>
            //                 <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
            //
            //                     async function restoreRow() {
            //                         let row = params.row as ISurvey & { _id: string };
            //                         row.isArchived = true;
            //
            //                         if (userToken === null || userToken === undefined) {
            //                             return;
            //                         }
            //                         await new SurveyDataController(userToken).update(row._id, row);
            //                         let all = await new SurveyDataController(userToken).getAll();
            //                         setList(all);
            //                     }
            //
            //                     if (window.confirm("Are you sure you want to archive this survey?")){
            //                         restoreRow().then();
            //                     }
            //
            //                 }}>
            //                     {/*<FontAwesomeIcon icon={faTrash} />*/}
            //                     {/*<FontAwesomeIcon icon={faBoxArchive} />*/}
            //                     Archive
            //                 </Button>
            //             </Tooltip>
            //         ),
            //     }
            // )

            columns.push(
                {
                    field: 'export_btn',
                    headerName: '',
                    sortable: false,
                    width: 100,
                    renderCell: (params: GridCellParams) => (
                        <Tooltip title={"Export Survey to JSON File"} arrow={true}>
                        <Button variant="outlined" color="error"  fullWidth={true} onClick={() => {


                            async function download(){
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }

                                let fileContent = await new SurveyDataController(userToken).downloadExport(params.row._id);
                                // window.open(url, '_blank');
                                console.log(fileContent)

                                // Create a Blob from the string data
                                const blob = new Blob([fileContent], { type: 'application/json' });

                                // Create a Blob URL
                                const blobUrl = window.URL.createObjectURL(blob);

                                // Create a download link
                                const downloadLink = document.createElement('a');
                                downloadLink.href = blobUrl;
                                downloadLink.download = params.row._id + ".json"; // Specify the file name here
                                // downloadLink.style.display = 'none';

                                // Trigger the download
                                // document.body.appendChild(downloadLink);
                                downloadLink.click();

                            }

                            download().then();


                        }}>
                            Export
                            {/*<FontAwesomeIcon icon={faTrash} />*/}
                        </Button>
                        </Tooltip>
                    ),
                }
            )
        }



        return columns;

    }, [user, userToken])




    React.useEffect(() => {
        async function loadData() {
            console.log(`userToken: ${userToken}`)
            if (userToken === null || userToken === undefined) {
                return;
            }
            try {
                let list = await new UserDataController(userToken).getAll();
                if (list === null || list === undefined) {
                    alert("Error loading");
                    return;
                }
                setUserList(list.filter((u) => u.role !== UserRole.CLIENT));
                // setCoordinatorList(list.filter((u) => u.role === UserRole.MANAGER || u.role === UserRole.ADMIN));
            } catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }

        }
        loadData().then();
    }, [userToken]);



    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new SurveyDataController(userToken).getAll();
            setList(all);
        }
        loadData().then();
    }, [userToken]);

    const [list, setList] = React.useState<ISurvey[]>([]);
    // const [showSidebar, setShowSidebar] = useLocalStorage("showSidebar", true);
    const [showAddNewCodeModal, setShowAddNewCodeModal] = React.useState<boolean>(false);


    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (userToken === null || userToken === undefined) {
                return;
            }
            return await new SurveyDataController(userToken).update(newRow._id, newRow as ISurvey);
        },
        [userToken],
    );

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log('handleProcessRowUpdateError', error)
        alert(error.message);
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    // const [file, setFile] = React.useState(null);

    const [showImportModal, setShowImportModal] = React.useState<boolean>(false);

    const [searchText, setSearchText] = React.useState<string>("");


    const filteredList = React.useMemo(() => {
        return list.filter((survey) => {

            if ( survey.caseNumber.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
            }

            if (survey.assigned_user_id !== undefined && survey.assigned_user_id !== null) {
                if ((survey.assigned_user_id as IUser).name.toLowerCase().includes(searchText.toLowerCase())) {
                    return true;
                }
            }

            return false;
        });
    }, [list, searchText]);


    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Surveys"}
                searchText={{text: searchText, setText: setSearchText, label: "Search (Case Number, Assigned User)"}}
                customButtons={[
                    {
                        label: "Create",
                        action: () => {
                            setShowAddNewCodeModal(true);
                        },
                        icon: <FontAwesomeIcon icon={faPlus} />
                    },
                    {
                        label: "Import",
                        action: () => {
                            setShowImportModal(true);
                        },
                        icon: <FontAwesomeIcon icon={faFileImport} />
                    },
                    // {
                    //     label: "Import Excel",
                    //     action: () => {
                    //         // loadData().then();
                    //         // setShowBulkImportModal(true);
                    //         // useNavigate().navigate("/dashboard/surveys/import");
                    //     },
                    //     icon: <FontAwesomeIcon icon={faFileExcel} />
                    // }
                ]}
            >

                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    // rows={userDataController.data}
                    rows={searchText === "" ? list : filteredList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[200]}
                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />

                <Modal
                    open={showAddNewCodeModal}
                    onClose={() => { setShowAddNewCodeModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        <AddNewSurveyModal
                            usersList={usersList}
                            onSave={async (survey: ISurvey) => {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                await new SurveyDataController(userToken).create(survey);
                                let all = await new SurveyDataController(userToken).getAll();
                                setList(all);
                                setShowAddNewCodeModal(false);
                            }}
                            onCancel={() => { setShowAddNewCodeModal(false) }}
                        ></AddNewSurveyModal>
                    </>}
                </Modal>

                <Modal
                    open={showImportModal}
                    onClose={() => { setShowImportModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    {<>
                        <ImportSurvey
                            userToken={userToken}
                            usersList={usersList}
                            onSave={async (survey: ISurvey) => {
                                if (userToken === null || userToken === undefined) {
                                    return;
                                }
                                await new SurveyDataController(userToken).create(survey);
                                let all = await new SurveyDataController(userToken).getAll();
                                setList(all);
                                setShowImportModal(false);
                            }}

                            onCancel={() => { setShowImportModal(false) }}
                        />
                    </>}
                </Modal>


            </AuthenticatedLayout>
        </div>
    );
}

function ImportSurvey(props: any) {

    const [importedData, setImportedData] = React.useState<SurveyObject[]>([]);

    function uploadImportDocument() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';
        input.onchange = async (event: any) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    const fileContent = e.target.result as string;
                    try {
                        console.log(fileContent);
                        const jsonData = JSON.parse(fileContent);
                        setImportedData (jsonData);
                        // we now have the data from the file.

                        console.log(jsonData);
                        // alert("Imported " + jsonData.length + " content.");
                    } catch (error: any) {
                        console.error('Error parsing JSON:', error);
                        alert("Error parsing JSON: " + error.message)
                    }
                }
            };

            reader.readAsText(event.target.files[0]);
            // reader.readAsArrayBuffer(event.target.files[0]);
        };
        input.click();
    }

    const form: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.TEXT,
            size: CustomFormFieldSizes.FULL,
            key: "caseNumber",
            label: "Case Number"
        },
        {
            kind: CustomFormFieldTypes.SELECT,
            size: CustomFormFieldSizes.FULL,
            key: "assigned_user_id",
            label: "Assigned User Id",
            options: [
                { label: "Un-assigned", value: null },
                ...props.usersList.map((opt: (IUser & {_id: string})) => { return { label: opt.name, value: opt._id }; })
            ]
        },
    ];
    const [survey, setSurvey] = React.useState<ISurvey>({
        caseNumber: "",
        assigned_user_id: null,
        content: [],
        locations: [],
    });

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Import Survey
            </Typography>

            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                uploadImportDocument();
            }}>
                Import JSON File
            </Button>

            <EditObject item={survey} setItem={setSurvey} form={form} columns={5}></EditObject>

            <br />

            <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                let newSurvey: ISurvey = {
                    caseNumber: survey.caseNumber,
                    assigned_user_id: survey.assigned_user_id,
                    content: importedData,
                    locations: [],
                };
                props.onSave(newSurvey);
            }} disabled={ importedData.length === 0 || survey === undefined || survey['caseNumber'] === "" }>CREATE</Button>

        </Box>
    );

}


export default SurveyManagementView;