import {Switch, TextField} from '@mui/material';
import React from 'react';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import {
    Clone,
    ISurvey,
    ISurveyAnswer, IUser,
    SurveyObject,
    SurveyObjectQuestion,
} from "../../types/interfaces";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {useParams} from "react-router-dom";
import {ReportDataController} from "../Reports/ReportDataController";
import {AuthContext} from "../../Library/AuthContext";
import {SurveyDataController} from "../Surveys/SurveyDataController";
import {SurveyObjectEdit} from "./Components/SurveyAnswerEdit";
import {AllConditionPassOnQuestion} from "../../utils/additional";

// export type exportType = SurveyObject & { questions: SurveyObjectQuestion & { answer: any }[] };

/*

you have to have the s3 bucket configured with cors.

[
    {
        "AllowedHeaders": [
            "*"
        ],
        "AllowedMethods": [
            "PUT",
            "POST",
            "DELETE",
            "GET",
            "HEAD"
        ],
        "AllowedOrigins": [
            "*"
        ],
        "ExposeHeaders": [
            "x-amz-server-side-encryption",
            "x-amz-request-id",
            "x-amz-id-2"
        ],
        "MaxAgeSeconds": 3000
    }
]

 */





function DetailViewBuilder(surveyAnswer: ISurveyAnswer, setSurveyAnswer: React.Dispatch<React.SetStateAction<ISurveyAnswer | undefined>>) {

    return function SurveyAnswerDetailView(row: any) {
        return (<>
            <div style={{padding: '0.5rem'}}>


                <div style={{ padding: '0.5rem' }}>
                    <TextField disabled={surveyAnswer.isCompleted}
                        fullWidth={true}
                        label="Label"
                        value={(surveyAnswer.answers[row.key] ?? {}).label || ""}
                        onChange={(e) => {
                            if (surveyAnswer === undefined) { return; }
                            let updatedSurveyAnswer = {...surveyAnswer};
                            if (updatedSurveyAnswer.answers[row.key] === undefined) {
                                updatedSurveyAnswer.answers[row.key] = {
                                    label: "",
                                    values: {}
                                }
                            }
                            updatedSurveyAnswer.answers[row.key].label = e.target.value;
                            setSurveyAnswer(updatedSurveyAnswer);
                        }}
                    />
                </div>

                { row.isConditional &&
                    (<div style={{ padding: '0.5rem' }}>
                        <Switch
                            // disabled={surveyAnswer.isCompleted}
                            checked={(surveyAnswer.answers[row.key] ?? {
                                values: {},
                                conditionalExists : false
                            }).conditionalExists || false}
                            onChange={(e) => {
                                if (surveyAnswer === undefined) { return; }
                                let updatedSurveyAnswer = {...surveyAnswer};
                                if (updatedSurveyAnswer.answers[row.key] === undefined) {
                                    updatedSurveyAnswer.answers[row.key] = {
                                        values: {},
                                        conditionalExists : false
                                    }
                                }
                                updatedSurveyAnswer.answers[row.key].conditionalExists = e.target.checked;
                                setSurveyAnswer(updatedSurveyAnswer);
                            }}
                            disabled={surveyAnswer.isCompleted}
                        />
                        <label>Installed</label>
                    </div>)
                }
                <SurveyObjectEdit surveyAnswer={surveyAnswer} setSurveyAnswer={setSurveyAnswer} row={row}  />
            </div>
        </>);

    }

}

function SurveyAnswerView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const { id } = useParams<{ id: string }>(); // answer id // so how do we do this if the person needs to do the whole survey on the web.

    const [surveyAnswer, setSurveyAnswer] = React.useState<ISurveyAnswer | undefined>(undefined);
    const [survey, setSurvey] = React.useState<ISurvey | undefined>(undefined);

    // async function loadData() {
    //     if (userToken === null || userToken === undefined) {
    //         return;
    //     }
    //     if (id === undefined) {
    //         return;
    //     }
    //     let answer = await new ReportDataController(userToken).getOneByAnswerId(id);
    //     setSurveyAnswer(answer);
    //
    //     console.log("answer", answer)
    //
    //     let survey = await new SurveyDataController(userToken).getOne(answer.survey_id);
    //     setSurvey(survey);
    //
    //     console.log("survey", survey)
    //     // setList(all);
    // }

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (id === undefined) {
            return;
        }
        let answer = await new ReportDataController(userToken).getOneByAnswerId(id);
        setSurveyAnswer(answer);

        console.log("answer", answer);

        let survey = await new SurveyDataController(userToken).getOne(answer.survey_id);
        setSurvey(survey);

        console.log("survey", survey);
        // setList(all);
    }, [userToken, id, setSurveyAnswer, setSurvey]); // Dependencies array


    React.useEffect(() => {
        loadData().then();
    }, [id, loadData]);

    async function saveData() {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (id === undefined) {
            return;
        }
        if (surveyAnswer === undefined) {
            return;
        }
        let answer = await new ReportDataController(userToken).update(surveyAnswer._id, surveyAnswer);
        setSurveyAnswer(answer);

        alert("Saved")

        await loadData();
    }



    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => {
            console.log("getDetailPanelContent called")
            if (surveyAnswer === undefined || survey === undefined) { return (<></>); }
            else {
                return DetailViewBuilder(surveyAnswer, setSurveyAnswer)(row)
            }

        },
        [survey, surveyAnswer],
    );

    const SurveyObjectsMemo = React.useMemo(() => {

        let objects: any[] = [];

        if (survey === undefined) {
            console.log("survey undefined")
            return [];
        }
        if (surveyAnswer === undefined) {
            console.log("surveyAnswer undefined")
            return [];
        } // TODO: change this!
        if (surveyAnswer.answers === undefined) {
            console.log("surveyAnswer.answers undefined")
            return [];
        }

        let surveyObjects = survey.content.map((surveyObject: SurveyObject) => {
            function test(object: SurveyObject) {
                if (object.isConditional === undefined || !object.isConditional) {
                    return true;
                }
                if (surveyAnswer === undefined) {
                    return false;
                }
                let objectAnswers = surveyAnswer.answers[object.key];
                if (objectAnswers === undefined) {
                    return false; // does objects that are conditional but have no answer show up
                }

                return objectAnswers.conditionalExists === true;
            }

            if (test(surveyObject)) {
                return surveyObject;
            }
            else {
                return {
                    key: surveyObject.key,
                    location: surveyObject.location,
                    isConditional: surveyObject.isConditional,
                    parent: surveyObject.parent,
                    questions: []
                } as SurveyObject;
            }



        });

        surveyObjects.forEach((surveyObject: SurveyObject) => {

            // TODO: still handle the installed/notinstalled

            // TODO: handle the condition based questions. This is where the question is only asked if the answer to a previous question is a certain value.

            let filteredQuestions = surveyObject.questions.filter((question: SurveyObjectQuestion) => {
                // let objectAnswers = surveyAnswer.answers[surveyObject.key];
                return AllConditionPassOnQuestion(question, surveyAnswer.answers[surveyObject.key] ?? { values: {}, clones: {} });
            });

            let questions = filteredQuestions.map((question: SurveyObjectQuestion) => {

                let objAnswer = surveyAnswer.answers[surveyObject.key];
                if (objAnswer === undefined || objAnswer.values === undefined) {
                    return {
                        ...question,
                        answer: ""
                    }
                }

                return {
                    ...question,
                    answer: objAnswer.values[question.key] ?? {}
                }
            })




            objects.push({
                ...surveyObject,
                questions: questions
            })

            if (surveyAnswer.clones === undefined) {
                return;
            }

            let clone = surveyAnswer.clones[surveyObject.key];
            if (clone) {
                clone.forEach((clone: Clone) => {
                    objects.push({
                        ...surveyObject,
                        key: clone.key,
                        questions: surveyObject.questions.map((question: SurveyObjectQuestion) => {


                            let objAnswer = surveyAnswer.answers[clone.key];
                            if (objAnswer === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }

                            if (objAnswer.values === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }



                            let answer = objAnswer.values[question.key];
                            if (answer === undefined) {
                                return {
                                    ...question,
                                    answer: ""
                                }
                            }

                            return {
                                ...question,
                                answer: objAnswer.values[question.key] ?? {}
                            }
                        })
                    })
                })
            }
        })


        // TODO: handle the clone.

        // TODO: merge the answers into the questions.

        return objects;
    }, [surveyAnswer, survey]);

    return (<>
        <AuthenticatedLayout  pageTitle={`Surveys Answers - ${id?.trim()}`}
            // onAddClicked={() => {
            //    // alert("Clicked Add New Item")
            //    setShowAddNewCodeModal(true);
            // }}
            onSaveClicked={() => {

                saveData().then();

                // TODO: save the survey answer. This is when an edit gets saved.
                // alert("Save not implemented yet")
            }}
        >

            {/*<pre>{JSON.stringify(surveyAnswer, undefined, 4)}</pre>*/}


            <div style={{ padding: '0.5rem' }}>
                <Switch
                    // disabled={surveyAnswer.isCompleted}
                    checked={surveyAnswer?.isCompleted || false}
                    onChange={(e) => {
                        if (surveyAnswer === undefined) { return; }
                        let updatedSurveyAnswer = {...surveyAnswer};
                        updatedSurveyAnswer.isCompleted = e.target.checked;
                        setSurveyAnswer(updatedSurveyAnswer);
                    }}
                    // disabled={props.field.readonly}
                />
                <label>Survey Completed</label>
            </div>



            <DataGridPro
                style={{ height: 'calc( 100% - 3.5rem )', width: '100%' }}
                columns={[
                    { field: 'key', headerName: 'Key', width: 200 },
                    { field: 'location', headerName: 'Object Name', width: 200 },
                    { field: 'isConditional', headerName: 'Is Conditional', width: 130 },
                    { field: 'parent', headerName: 'Parent', width: 130 },
                    { field: 'count', headerName: "Questions Count", width: 150, valueGetter: (params: GridValueGetterParams) => {
                        let obj = params.row as SurveyObject;
                        return obj.questions.length;
                    } },
                ]}
                rows={SurveyObjectsMemo}
                rowThreshold={0}
                getRowId={(row) => row.key || row.location}
                getDetailPanelHeight={(row) => {
                    // row.row
                    // return 800;

                    let questionCount = (row.row as SurveyObject).questions.length;
                    if (questionCount === 0) {
                        return 275;
                    }
                    // 350
                    return 400 + ( (row.row as SurveyObject).questions.length * 50);
                }}
                getDetailPanelContent={getDetailPanelContent}
                disableRowSelectionOnClick
            />
        </AuthenticatedLayout>
    </>);
}

export default SurveyAnswerView;