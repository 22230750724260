// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {
    DataGrid,
    GridColDef, GridRowModel,
    GridValidRowModel
} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {CustomFormField, IUser} from "../../types/interfaces";
import {ChangeUserController, UserDataController} from "./UserDataController";
import {AuthContext} from "../../Library/AuthContext";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {
    Box,
    Button,
    FormControlLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {EditObject} from "../../Shared/Components/EditObject";
// https://mui.com/material-ui/icons/#font-awesome


function UserAgentModal(props: { user: IUser })  {

    // const [usersList, setUserList] = React.useState<(IUser & {_id: string})[]>([])

    const agentList = React.useMemo(() => {
        return props.user.userAgentLog?.map((agent) => {
            return {userAgent: agent}
        });
    }, [props.user.userAgentLog]);

    const [show, setShow] = React.useState<boolean>(false);

    return (
        <>
            <Modal
            open={show}
            onClose={() => {
                setShow(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 640,
                height: 500,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Stack style={{height: '100%'}}>


                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{mb: 2, textAlign: 'center'}}>
                        {props.user.name}'s User Agents
                    </Typography>

                    <DataGrid

                        // make sure to set height to 100% so that the grid fills the whole container
                        style={{height: '100%'}}
                        rows={agentList ?? []}
                        columns={[
                            {field: 'userAgent', headerName: 'User Agent', width: 1000, editable: false},
                        ]}
                        getRowId={(row: GridValidRowModel) => row.userAgent}
                        pageSizeOptions={[200]}
                        disableRowSelectionOnClick/>
                </Stack>

            </Box>
        </Modal><Tooltip title={"User Agent"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                User Agents
                {/*<FontAwesomeIcon icon={faCopy} />*/}
            </Button>
        </Tooltip>
        </>


    );
}


export const AddNewUser = ({ open, onClose, onAddNewUser } : { open: boolean, onClose: () => void, onAddNewUser: (user: IUser) => Promise<boolean> }) => {
    const [item, setItem] = React.useState<any>({});

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "name",
                    label: "Name",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "email_address",
                    label: "Email Address",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "phone_number",
                    label: "Phone Number",
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.FULL,
                    key: "role",
                    label: "Role",
                    options: [
                        { label: "Admin", value: UserRole.ADMIN },
                        { label: "Manager / PC", value: UserRole.MANAGER },
                        { label: "Technician", value: UserRole.TECHNICIAN },
                        { label: "Client", value: UserRole.CLIENT }
                    ]
                },
            ];


            return fields;
        },
        []
    );

    const disableButton = React.useMemo(() => {
        return item.name === undefined || item.name === "" ||
            item.email_address === undefined || item.email_address === "" ||
            item.phone_number === undefined || item.phone_number === "" ||
            item.role === undefined || item.role === "";
    }, [item]);

    return (<>
        <Modal open={open} onClose={() => {
            setItem({});
            onClose();
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Create New User
                </Typography>
                <EditObject
                    item={item}
                    setItem={setItem}
                    form={fields}
                    columns={12}
                />
                <br />

                <Button type={"submit"} variant={"contained"} size={"large"} disabled={disableButton} fullWidth={true} onClick={async () => {
                    let userToCreate: IUser = {
                        _id: "", // this will be ignored by the server.
                        name: item.name,
                        email_address: item.email_address,
                        phone_number: item.phone_number,
                        role: item.role
                    };

                    try {
                        if (await onAddNewUser(userToCreate)) {
                            setItem({});
                            onClose();
                        } else {
                            alert("Invalid User");
                        }
                    }
                    catch (e) {
                        console.log(e);
                        alert("Error Creating User");
                    }


                }}>CREATE</Button>
            </Box>
        </Modal>
    </>);
};

const EditableStatusCell = ({ value, onChange }: any) => {
    const handleStatusChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <Select value={value} onChange={handleStatusChange} fullWidth={true}>

            {[
                { label: "Admin", value: UserRole.ADMIN },
                { label: "Manager / PC", value: UserRole.MANAGER },
                { label: "Technician", value: UserRole.TECHNICIAN },
                { label: "Client", value: UserRole.CLIENT }
            ].map((statusOption) => (
                <MenuItem key={statusOption.label} value={statusOption.value}>
                    {statusOption.label}
                </MenuItem>
        ))}

            {/*{Object.values(UserRole).map((statusOption) => (*/}
            {/*    <MenuItem key={statusOption} value={statusOption}>*/}
            {/*        {statusOption}*/}
            {/*    </MenuItem>*/}
            {/*))}*/}
        </Select>
    );
};

const ToggleCell = ({ value, onToggle, disabled }: any) => {
    return (
        <FormControlLabel
            control={<Switch checked={value} onChange={(e) => onToggle(e.target.checked)} disabled={disabled} />}
            label=""
        />
    );
}

function UserManagementView() {

    const {
        user,
        userToken,
        setUserToken,
        setAdminUserToken
    } = React.useContext(AuthContext)!;


    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (userToken === null || userToken === undefined) {
                return;
            }

            return await new UserDataController(userToken).update(newRow._id, newRow as IUser);
        },
        [userToken],
    );


    const columns = React.useMemo<GridColDef[]>(() => {

        const columns: GridColDef[] = [



            { field :'name', headerName: 'Name', width: 250, editable: true },
            // { field: 'e', headerName: 'ID', width: 150, editable: false },
            { field :'phone_number', headerName: 'Phone Number', width: 150, editable: true },

            { field: 'email_address', headerName: 'Email Address', width: 350, editable: false },
            {
                field: 'role',
                headerName: 'Role',
                width: 200,
                renderCell: (params) => (
                    <EditableStatusCell
                        value={params.value}
                        onChange={async (newValue: string) => {
                            console.log(newValue)
                            let oldRow = params.row as IUser;
                            let newRow = {...oldRow, role: newValue} as IUser;
                            let resultRow = await processRowUpdate(newRow, oldRow);
                            if (resultRow === undefined) {
                                console.log("resultRow is undefined")
                                return;
                            }
                            params.api.updateRows([resultRow]);
                        }}
                    />
                ),
            },
            {
                field: 'isDeactivated',
                headerName: 'Locked',
                width: 120,
                renderCell: (params) => (
                    <ToggleCell
                        value={params.value as boolean}
                        onToggle={async (newValue: boolean) => {
                            let oldRow = params.row as IUser;
                            let newRow = {...oldRow, isDeactivated: newValue} as IUser;
                            let resultRow = await processRowUpdate(newRow, oldRow);
                            if (resultRow === undefined) {
                                console.log("resultRow is undefined")
                                return;
                            }
                            params.api.updateRows([resultRow]);
                            // Handle toggle here and update the data source (e.g., using setState).
                        }}
                        disabled={(params.row as IUser).role === UserRole.ADMIN}
                    />
                ),
            },
            { field: '_id', headerName: 'ID', width: 400, editable: false },

        ]

        if (user?.role === UserRole.ADMIN) {
            columns.push({ field: 'login_as', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                    <>
                        <Button variant="outlined" color="primary" disabled={ (params.row as IUser).role === UserRole.ADMIN || (params.row as IUser).isDeactivated } fullWidth={true} onClick={async () => {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            let controller = new ChangeUserController(userToken);
                            let result = await controller.changeUser(params.row as IUser);
                            if (result === undefined) {
                                return;
                            }
                            setAdminUserToken(userToken);
                            setUserToken(result);
                        }}>Login As</Button>
                    </>
                ),
            });

            columns.push({ field: 'lastTokenRefresh', headerName: 'Last Refresh', width: 250, editable: false,
                valueFormatter: (params) => {
                    try {
                        if (params.value === undefined || params.value === null) {
                            return "";
                        }
                        let date = new Date(params.value as string);
                        return date.toLocaleString();
                    }
                    catch (e) {
                        // console.log(e);
                        return "";
                    }

                }
            })

            columns.push({ field: 'user_agents', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                   <UserAgentModal user={params.row as IUser} />
                ),
            });

            columns.push({ field: 'delete', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                    <>
                        <Button variant="outlined" color="primary" disabled={(params.row as IUser)._id === user._id} fullWidth={true} onClick={async () => {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            if (window.confirm(`Are you sure you want to delete [${(params.row as IUser).email_address}?`)) {

                                let controller = new UserDataController(userToken);
                                await controller.delete((params.row as IUser)._id);
                                let all = await controller.getAll();
                                setList(all);
                            }
                        }}>Delete</Button>
                    </>
                ),
            });
        }

        return columns;
    }, [user, userToken, setUserToken, setAdminUserToken, processRowUpdate]);

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new UserDataController(userToken).getAll();
            setList(all);
        }
        loadData().then();
    }, [userToken]);

    const [list, setList] = React.useState<IUser[]>([]);
    const [showAddNewUserModal, setShowAddNewUserModal] = React.useState<boolean>(false);

    const [searchText, setSearchText] = React.useState<string>("");


    const filteredList = React.useMemo(() => {
        return list.filter((user) => {
            return user.name.toLowerCase().includes(searchText.toLowerCase()) ||
                user.email_address.toLowerCase().includes(searchText.toLowerCase()) ||
                user.phone_number.toLowerCase().includes(searchText.toLowerCase())
        });
    }, [list, searchText]);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log('handleProcessRowUpdateError', error)
        alert(error.message);
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Users"}
                 onAddClicked={() => {
                     setShowAddNewUserModal(true)
                 }}
                                  searchText={{text: searchText, setText: setSearchText, label: "Search (Name, Email, Phone)"}}
            >
                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={searchText === "" ? list : filteredList}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[200]}
                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                />


                <AddNewUser open={showAddNewUserModal} onClose={() => {
                    setShowAddNewUserModal(false)
                }} onAddNewUser={async (user) => {
                    if (userToken === null || userToken === undefined) {
                        return false;
                    }

                    await new UserDataController(userToken).create(user);
                    let all = await new UserDataController(userToken).getAll();
                    setList(all)
                    return true;
                }} />

            </AuthenticatedLayout>
        </div>
    );
}

export default UserManagementView;