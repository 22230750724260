import {
    CustomFormField,
    ICondition,
    IQuickQuestion, IQuickQuestionGroup,
    ISurvey,
    SurveyObject,
    SurveyObjectQuestion
} from "../../../types/interfaces";
import React from "react";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../../types/enums";
import {DataGrid, GridCellParams, GridRowSelectionModel, GridValidRowModel} from "@mui/x-data-grid";
import {
    Box,
    Button, FormControl,
    FormControlLabel,
    FormGroup,
    IconButton, InputLabel, MenuItem,
    Modal,
    Paper, Select,
    Stack, Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faArrowDown,
    faArrowUp, faCircleCheck,
    faPlus, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {EditObject} from "../../../Shared/Components/EditObject";
import {
    AddNewListQuestion,
    AddNewQuestion,
    EditButtonModalContainer,
} from "./QuestionView";
import DeleteIcon from "@mui/icons-material/Delete";
import {BankDataController_QuickQuestion} from "../../Bank/BankDataController_QuickQuestion";
import {UploadOutlined as UploadOutlineIcon} from "@mui/icons-material";
import {faObjectGroup} from "@fortawesome/free-regular-svg-icons";
import {BankDataController_QuickQuestionGroup} from "../../Bank/BankDataController_QuickQuestionGroup";
import {AuthContext} from "../../../Library/AuthContext";
import {generateCode} from "../SurveyEditorView";

function RenderCellMoveQuestionToAnotherObject(props: { item: SurveyObject, setItem: (item: SurveyObject) => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void, userToken: string, params: GridCellParams}) {
    // return function () {

    const row = props.params.row as SurveyObjectQuestion;
    const [open, setOpen] = React.useState(false);

    const filteredObjectMemo = React.useMemo(
        () => {
            return props.survey.content.filter((content: SurveyObject) => content.key !== props.item.key);
        },
        [props.survey.content, props.item]
    );

    const [selectedObject, setSelectedObject] = React.useState('');

    const handleClose = () => {
        setSelectedObject('');
        setOpen(false);
    };

    const handleMoveButtonClick = () => {
        // Add your logic here for moving to another object
        console.log(`Moving to ${selectedObject}`);


        // get list of questions from source object, and remove the question from the list.
        let newParentItemQuestions = [...props.item.questions];
        let questionIndex = newParentItemQuestions.findIndex((question) => question.key === row.key);
        let question = newParentItemQuestions[questionIndex];
        newParentItemQuestions.splice(questionIndex, 1);

        // get the destination object
        let destinationObject = props.survey.content.filter((content: SurveyObject) => content.key === selectedObject)[0];
        let newDestinationObjectQuestions = [...destinationObject.questions];
        newDestinationObjectQuestions.push(question);

        // update the source object
        let newParentItem: SurveyObject = {...props.item, questions: newParentItemQuestions};
        // replace new parent item in the survey

        // update the destination object
        let newDestinationObject: SurveyObject = {...destinationObject, questions: newDestinationObjectQuestions};


        let newSurvey = {...props.survey};
        newSurvey.content = newSurvey.content.map((content) => {
            if (content.key === newDestinationObject.key) {
                return newDestinationObject;
            }

            if (content.key === newParentItem.key) {
                return newParentItem;
            }
            return content;
        })
        props.setSurvey(newSurvey);

        // close the modal





        handleClose();
    };

    return (<>
        {open && (
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, minWidth: 300 }}>
                    {/*<IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>*/}
{/*                        <CloseIcon />
                    </IconButton>*/}

                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Move Question to Another Object
                    </Typography>

                    <Stack direction={"column"} spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel>Select an Object</InputLabel>
                            <Select
                                value={selectedObject}
                                onChange={(event: any) => {
                                    setSelectedObject(event.target.value as string);
                                }}
                                label="Select an Object"
                            >
                                {filteredObjectMemo.map((object: SurveyObject) => {
                                    return (<MenuItem value={object.key}>{object.key} - {object.location}</MenuItem>)
                                })}
                                {/*<MenuItem value="Object 1">Object 1</MenuItem>*/}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleMoveButtonClick}
                            disabled={!selectedObject}
                        >
                            Move
                        </Button>
                    </Stack>



                </Box>
            </Modal>
        )}
        <Tooltip title="Move Question To Another Object" arrow><IconButton color={"warning"} onClick={() => {
            // alert("move question to another object");
            setOpen(true);
        }}>
            <FontAwesomeIcon icon={faObjectGroup} />
        </IconButton></Tooltip>

    </>)
    // }
}


function RenderCellValidInvalidIcon(props: { item: SurveyObject, setItem: (item: SurveyObject) => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void, userToken: string, params: GridCellParams}) {


    const row = props.params.row as SurveyObjectQuestion;

    const warningsMemo = React.useMemo(() => {

        let warnings: string[] = [];

        row.conditions?.forEach((condition: ICondition) => {
            let selectedQuestions = props.item.questions.filter((q) => q.key === condition.key)[0];
            if (selectedQuestions === undefined) {
                warnings.push(`Question ${row.key} is missing.`);
            }
        });

        // row.requiredConditionFlags?.forEach((condition: string) => {
        //     if (props.survey.conditionFlags === undefined || props.survey.conditionFlags[condition] === undefined) {
        //         warnings.push(`Condition Flag ${condition} is missing.`);
        //     }
        // })

        return warnings
    }, [row, props.item.questions, props.survey.conditionFlags])

    const warningsTitleMemo = React.useMemo(() => {
        if (warningsMemo.length === 0) {
            return "No Warnings";
        }

        return (<>
            {warningsMemo.map((warning) => {
                return (<>{warning}<br /></>)
            })}
        </>)
    }, [warningsMemo])

    return (<>
        {/*{warningsMemo.length}*/}

        <Tooltip title={
            warningsTitleMemo
            // warningsMemo.length === 0 ? "No Warnings" : warningsMemo.join("\n")
        } >
            <FontAwesomeIcon
                icon={warningsMemo.length === 0 ? faCircleCheck : faTriangleExclamation}
                style={{
                    color: warningsMemo.length === 0 ? 'green' : 'red',
                    fontSize: '2em'
                }}
            />
            {/*{warningsMemo.length === 0 && (<FontAwesomeIcon style={{color: 'green', fontSize: '2em'}} icon={faCircleCheck} />)}*/}
            {/*{warningsMemo.length > 0 && (<FontAwesomeIcon style={{color: 'red', fontSize: '2em'}} icon={faTriangleExclamation} />)}*/}

        </Tooltip>


    </>)
}




export function ContentAccordionDetail(props: { item: SurveyObject, setItem: (item: SurveyObject) => void, survey: ISurvey, setSurvey: (survey: ISurvey) => void, userToken: string }) {
    const { item } = props;

    const [openNewQuestion, setOpenNewQuestion] = React.useState(false);
    const [openImportQuestion, setOpenImportQuestion] = React.useState(false);

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.HALF,
                    key: "key",
                    label: "Key"
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.HALF,
                    key: "location",
                    label: "Object Name",
                },
                {
                    kind: CustomFormFieldTypes.TOGGLE,
                    size: CustomFormFieldSizes.HALF,
                    key: "isConditional",
                    label: "Installed / Not Installed",
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.HALF,
                    key: "parent",
                    label: "Parent",
                    options: [
                        {label: "", value: ""},
                        ...props.survey.content.filter((content: SurveyObject) => content.key !== item.key).map((content: SurveyObject) => ({ label: content.key + " - " + content.location, value: content.key }))
                    ]
                },

            ];

            return fields;
        },
        [item, props.survey]
    );

    function AddNewQuestionButtonClicked(question: SurveyObjectQuestion) {
        let questions: SurveyObjectQuestion[] = [...item.questions, question]
        console.log(`item: ${JSON.stringify(item)}, question: ${JSON.stringify(question)}`);
        props.setItem({...item, questions});
        return true;
    }

    function MoveToTopBtn (params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question To Top" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions.splice(index, 1);
            questions.unshift(temp);
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleUp} />
        </IconButton></Tooltip>)
    }

    function MoveToUpBtn(params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question Up" arrow><IconButton disabled={index === 0} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions[index] = questions[index - 1];
            questions[index - 1] = temp;
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowUp} />
        </IconButton></Tooltip>)
    }

    function MoveDownBtn(params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question Down" arrow><IconButton disabled={index === item.questions.length - 1} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions[index] = questions[index + 1];
            questions[index + 1] = temp;
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowDown} />
        </IconButton></Tooltip>)
    }

    function MoveToBottomBtn  (params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<Tooltip title="Move Question To Bottom" arrow><IconButton disabled={index === item.questions.length - 1} color={"warning"} onClick={() => {
            let questions: SurveyObjectQuestion[] = [...item.questions];
            let temp = questions[index];
            questions.splice(index, 1);
            questions.push(temp);
            props.setItem({...item, questions});
        }}>
            <FontAwesomeIcon icon={faArrowAltCircleDown} />
        </IconButton></Tooltip>)
    }

    function DeleteBtn(params: GridCellParams) {
        return (<Tooltip title="Delete Question" arrow><IconButton color={"error"} onClick={() => {
            if (window.confirm(`Are you sure you want to delete ${params.row.key}?`)) {
                let questions: SurveyObjectQuestion[] = item.questions.filter((question: SurveyObjectQuestion) => question.key !== params.row.key);
                props.setItem({...item, questions});
            }
        }}><DeleteIcon /></IconButton></Tooltip>)
    }

    function EditBtn(params: GridCellParams) {
        let index = item.questions.findIndex((question: SurveyObjectQuestion) => question.key === params.row.key);
        return (<>
            <EditButtonModalContainer survey={props.survey} item={item} setItem={props.setItem} params={params} index={index} />
        </>)
    }

    function ExportBtn(params: GridCellParams) {
        return (
            <Tooltip title={"Export Question to Bank"}>
            <IconButton color={"secondary"} onClick={() => {
            if (props.userToken === null || props.userToken === undefined) {
                alert("You must be logged in to export.");
                return;
            }
            let objectToCreate: IQuickQuestion = {
                content : params.row as SurveyObjectQuestion,
            } as IQuickQuestion;

            objectToCreate.content.conditions = [];

            new BankDataController_QuickQuestion(props.userToken).create(objectToCreate).then((response) => {
                alert("Exported successfully.");
            });

            }}><UploadOutlineIcon /></IconButton></Tooltip>)
    }




    const columns = [
        { field: 'valid', headerName: '', width: 50, renderCell:  (params: GridCellParams) => (<><RenderCellValidInvalidIcon {...props} params={params}  /></>) },
        { field: 'questionText', headerName: 'Question', width: 500 },
        { field: 'type', headerName: 'Type', width: 150, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.type } },
        { field: 'key', headerName: 'Key', width: 150 },
        { field: 'options', headerName: 'Options', width: 250, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.options?.join(", ") ?? "" }},
        { field: 'defaultValue', headerName: 'Default Value', width: 250, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.defaultValue }},
        { field: 'move_to_top_btn', headerName: '', width: 75, renderCell: MoveToTopBtn },
        { field: 'move_up_btn', headerName: '', width: 75, renderCell: MoveToUpBtn },
        { field: 'move_down_btn', headerName: '', width: 75, renderCell: MoveDownBtn },
        { field: 'move_to_bottom_btn', headerName: '', width: 75, renderCell: MoveToBottomBtn },
        { field: 'delete_btn', headerName: '', width: 75, renderCell: DeleteBtn},
        { field: 'edit_btn', headerName: '', width: 75, renderCell: EditBtn },
        { field: 'export_btn', headerName: '', width: 75, renderCell: ExportBtn },
        { field: 'move_question_to_another_object', headerName: '', width: 75,
            renderCell: (params: GridCellParams) => (<><RenderCellMoveQuestionToAnotherObject {...props} params={params}  /></>),
        },
    ]

    const [showBulkConditionAddModal, setShowBulkConditionAddModal] = React.useState<boolean>(false);

    const [showBulkImportQuestionModal, setShowBulkImportQuestionModal] = React.useState<boolean>(false);


    const [bulkSelectMode, setBulkSelectMode] = React.useState<boolean>(false);

    const [selectionModel, setSelectionModel] = React.useState([]);
    const handleSelection = (newSelectionModel: any) => {
        setSelectionModel(newSelectionModel);
        // Handle the selection
    };

    return (<>
        <div style={{ padding: "1rem"}}>
            <EditObject item={item} setItem={props.setItem} form={fields} columns={12} />
            <br />
            <br />
            <Stack direction={"column"} spacing={2}>
                <Stack direction={"row"} spacing={2}>
                    <Button variant="contained" onClick={() => {setOpenNewQuestion(true);}}>Add New Question</Button>
                    <Button variant="contained" onClick={() => {setOpenImportQuestion(true);}}>Import Question</Button>
                    <Button variant="contained" disabled={item.questions.length === 0} onClick={() => {if (window.confirm(`Are you sure you want to delete all questions?`)) {props.setItem({...item, questions: []})}}}>Delete All Questions</Button>

                    <Button variant={"contained"} disabled={item.questions.length === 0} onClick={() => {
                        setShowBulkConditionAddModal(true)
                    } }>Quick Bulk Add Condition</Button>

                    <Button variant="contained" onClick={() => {
                        setShowBulkImportQuestionModal(true)
                    }}>Import Bulk from Bank</Button>


                    {/*<p>Selected: {selectionModel.length} {selectionModel.length === 0}</p>*/}



                </Stack>


                <Stack direction={"row"} spacing={2}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch
                                checked={bulkSelectMode}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setBulkSelectMode(event.target.checked);
                                    if (!event.target.checked) {
                                        setSelectionModel([]);
                                    }
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Bulk Question Select"
                        />
                    </FormGroup>


                    <Button variant="contained" disabled={!bulkSelectMode || selectionModel.length === 0} onClick={() => {
                        // setOpenImportQuestion(true);

                        async function exportBulkQuestions() {
                            let questionsToExport = selectionModel.map((key: string) => {
                                return item.questions.filter((question: SurveyObjectQuestion) => question.key === key)[0];
                            });

                            let groupName = window.prompt("Enter a name for the group", "New Group");

                            if (groupName === null || groupName === undefined || groupName === "") {
                                return;
                            }

                            let groupToCreate: IQuickQuestionGroup = {
                                name: groupName,
                                content: questionsToExport
                            } as IQuickQuestionGroup;

                            new BankDataController_QuickQuestionGroup(props.userToken).create(groupToCreate).then((response) => {
                                alert("Exported successfully.");
                            });
                        }


                        exportBulkQuestions().then();


                    }}>Export Selected to Bank</Button>

                    <Button variant="contained" disabled={!bulkSelectMode || selectionModel.length === 0} onClick={() => {
                        // delete selected questions
                        if (window.confirm(`Are you sure you want to delete ${selectionModel.length} questions?`)) {
                            // loop over the selected questions and remove them from the list.
                            let questionsToRemove = selectionModel.map((key: string) => {
                                return item.questions.filter((question: SurveyObjectQuestion) => question.key === key)[0];
                            });
                            let newParentItem = {...item};
                            newParentItem.questions = newParentItem.questions.filter((question) => !questionsToRemove.includes(question));
                            props.setItem(newParentItem);
                        }
                    }}>Delete Selected</Button>
                </Stack>

            </Stack>



            <br />
            <br />
            <AddNewQuestion survey={props.survey} onAddNewQuestion={AddNewQuestionButtonClicked} onClose={() => setOpenNewQuestion(false)} open={openNewQuestion} surveyObject={item}/>

            <AddNewListQuestion onAddNewQuestion={AddNewQuestionButtonClicked} onClose={() => setOpenImportQuestion(false)} open={openImportQuestion}/>

            {item.questions.length > 0 && (
                <DataGrid
                    rows={item.questions}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row.key} // was _id
                    pageSizeOptions={[200]}
                    disableRowSelectionOnClick
                    checkboxSelection={bulkSelectMode}
                    onRowSelectionModelChange={handleSelection}
                    rowSelectionModel={selectionModel}
                    // may have to do some smarts with is row selecatble
                />
            ) }



            <QuickBulkAddConditionModal
                item={item}
                setItem={props.setItem}
                showModal={showBulkConditionAddModal}
                setShowModal={setShowBulkConditionAddModal}
            />

            <BulkImportQuestionModal
                item={item}
                setItem={props.setItem}
                showModal={showBulkImportQuestionModal}
                setShowModal={setShowBulkImportQuestionModal}
            />


        </div>
    </>);
}

export const QuickBulkAddConditionModal = (props : { item: SurveyObject, setItem: (item: SurveyObject) => void, showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const columns = [
        { field: 'questionText', headerName: 'Question', width: 500 },
        { field: 'type', headerName: 'Type', width: 150, valueGetter: (params: GridCellParams) => { return (params.row as SurveyObjectQuestion).type.type } },
        { field: 'key', headerName: 'Key', width: 150 },
    ];

    const [sourceQuestion, setSourceQuestion] = React.useState<any>({});

    const quickConditions = React.useMemo(
        () => {
            let allowedTypes = ["select", "toggle"];
            let questions = props.item.questions.filter((question) => question.key !== props.item.key && allowedTypes.includes(question.type.type) );

            let conditions: ICondition[] = [];

            questions.forEach((question: SurveyObjectQuestion) => {
                switch (question.type.type) {
                    case "select":
                        question.type.options?.forEach((option: string) => {
                            conditions.push({
                                key: question.key,
                                op: "=",
                                value: option
                            });

                            conditions.push({
                                key: question.key,
                                op: "≠",
                                value: option
                            });
                        })

                        break;

                    case "toggle":
                        conditions.push({
                            key: question.key,
                            op: "=",
                            value: true
                        });

                        conditions.push({
                            key: question.key,
                            op: "=",
                            value: false
                        });


                }
            });
            return conditions;
        }, [props.item.questions, props.item.key]
    );


    return (<>
        <Modal open={props.showModal} onClose={() => {
            props.setShowModal(false);
        }}>
            {props.showModal ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Quick Bulk Add Condition
                    </Typography>

                    <DataGrid
                        rows={props.item.questions}
                        columns={columns}
                        getRowId={(row: GridValidRowModel) => row.key} // was _id
                        pageSizeOptions={[200]}
                        disableRowSelectionOnClick
                        checkboxSelection
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />

                    <br />
                    <hr />
                    <br />


                    <Paper variant={"outlined"} sx={{padding: 2}}>
                        <EditObject item={sourceQuestion} setItem={setSourceQuestion} form={[
                            {
                                kind: CustomFormFieldTypes.SELECT,
                                size: CustomFormFieldSizes.ONE,
                                key: "quickCondition",
                                label: "QuickCondition",
                                options: quickConditions.map((condition) => {
                                    let selectedQuestions = props.item.questions.filter((q) => q.key === condition.key)[0];
                                    return {label: `${selectedQuestions.questionText} (${condition.key}) ${condition.op} ${condition.value}`, value: JSON.stringify(condition) }
                                })
                                // TODO: check that it isn't already in the list
                            }
                        ]} columns={1} />

                        {/*<pre>{JSON.stringify(rowSelectionModel, undefined, 4)}</pre>*/}

                        <Button fullWidth={true} disabled={sourceQuestion === null || rowSelectionModel.length === 0} variant={"contained"} onClick={() => {
                            // remove the selected source question from the list of selected questions.

                            let quickCondition = JSON.parse(sourceQuestion['quickCondition']) as ICondition;
                            let newParentItem = {...props.item};

                            // loop over the selected questions and add the condition to each one.
                            newParentItem.questions.map((question) => {
                                let updatedQuestion = {...question};
                                if (rowSelectionModel.includes(updatedQuestion.key) && updatedQuestion.key !== quickCondition.key) {
                                    if (updatedQuestion.conditions === undefined) {
                                        updatedQuestion.conditions = [];
                                    }
                                    updatedQuestion.conditions.push(quickCondition);
                                }
                                return updatedQuestion;
                            })


                            props.setItem(newParentItem);
                            props.setShowModal(false);
                            setRowSelectionModel([]);
                            setSourceQuestion({});

                        } }>Add Condition</Button>

                    </Paper>

                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};


export const BulkImportQuestionModal = (props : { item: SurveyObject, setItem: (item: SurveyObject) => void, showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>> }) => {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const columns = [
        { field: '_id', headerName: 'ID', width: 250, editable: false },
        { field: 'name', headerName: 'Name', width: 250, editable: false },
        { field: 'questions.length', headerName: 'Question Count', width: 150, editable: false, valueGetter: (params: GridCellParams) => {
            let quickQuestion: IQuickQuestionGroup = params.row as IQuickQuestionGroup;
            return quickQuestion.content.length;
        }},

        {
            field: 'add_btn',
            headerName: '',
            sortable: false,
            width: 75,
            renderCell: (params: GridCellParams) => {
                return (<IconButton color={"warning"} onClick={() => {

                    let group = params.row as IQuickQuestionGroup;
                    let newParentItemQuestions = [...props.item.questions];
                    let newKeysMap = new Map<string, string>();

                    group.content.forEach((question) => {
                        let newKey = generateCode();
                        newKeysMap.set(question.key, newKey);
                        question.key = newKey;
                    })

                    group.content.forEach((question) => {
                        let updatedQuestion = {...question, key: newKeysMap.get(question.key) ?? question.key };
                        updatedQuestion.conditions = updatedQuestion.conditions?.map((condition) => {
                            let updatedCondition = {...condition};
                            updatedCondition.key = newKeysMap.get(updatedCondition.key) ?? updatedCondition.key;
                            return updatedCondition;
                        })
                        // this theoretically should remove unused keys.
                        .filter((condition) => group.content.map((question) => question.key).includes(condition.key));


                        if (updatedQuestion.showInStandardReport !== undefined && updatedQuestion.showInStandardReport !== null && typeof updatedQuestion.showInStandardReport !== "boolean") {
                            let currentCondition = updatedQuestion.showInStandardReport;
                            let updatedCondition = {...currentCondition, key: newKeysMap.get(currentCondition.key) ?? currentCondition.key};
                            console.log(`updatedCondition: ${JSON.stringify(updatedCondition)}`);
                            updatedQuestion.showInStandardReport = updatedCondition;
                        }

                        if (updatedQuestion.showInExceptionReport !== undefined && updatedQuestion.showInExceptionReport !== null && typeof updatedQuestion.showInExceptionReport !== "boolean") {
                            let currentCondition = updatedQuestion.showInExceptionReport;
                            let updatedCondition = {...currentCondition, key: newKeysMap.get(currentCondition.key) ?? currentCondition.key};
                            console.log(`updatedCondition: ${JSON.stringify(updatedCondition)}`);
                            updatedQuestion.showInExceptionReport = updatedCondition;
                        }

                        newParentItemQuestions.push(updatedQuestion);
                    })

                    let newParentItem: SurveyObject = {...props.item, questions: newParentItemQuestions};

                    console.log(`newParentItem: ${JSON.stringify(newParentItem)}`);
                    props.setItem(newParentItem);
                    props.setShowModal(false);

                }}>
                    <FontAwesomeIcon icon={faPlus} />
                </IconButton>)
            }
        }

    ]


    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new BankDataController_QuickQuestionGroup(userToken).getAll();
            setQuickQuestionGroupsList(all);
        }
        loadData().then();
    }, [userToken]);

    const [quickQuestionGroupsList, setQuickQuestionGroupsList] = React.useState<IQuickQuestionGroup[]>([]);



    return (<>
        <Modal open={props.showModal} onClose={() => {
            props.setShowModal(false);
        }}>
            {props.showModal ? (<>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '60%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                        Import Group Questions from Bank
                    </Typography>

                    <DataGrid
                        rows={quickQuestionGroupsList}
                        columns={columns}
                        getRowId={(row: GridValidRowModel) => row._id} // was _id
                        pageSizeOptions={[200]}
                        disableRowSelectionOnClick
                    />


                </Box>
            </>) : (<></>)}
        </Modal>
    </>);
};

